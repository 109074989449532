import React from 'react';
import earnipayLogoWhite from 'assets/images/icons/earnipay-white.svg';
import dollarJar from 'assets/images/bgs/dollar-coin-jar.svg'
import greenRightArrow from 'assets/images/icons/green-right-arrow.svg';
import dollarJarBg from 'assets/images/bgs/dollar-jar-bg.svg';

const DollarSavingsOtw = () => {
  return (
    <div>
      <div className='container flex flex-col lg:flex-row justify-between items-center text-white rounded-3xl' style={{backgroundImage: `url(${dollarJarBg})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover'}}>
        <div className='flex-1 p-6 md:p-10 md:ml-10 space-y-5'>
            <div><img src={earnipayLogoWhite} alt="Earnipay Logo" className="w-auto h-8 md:h-10" /></div>
            <div className='text-3xl md:text-4xl lg:text-6xl xl:text-[80px] font-bold leading-tight'>Dollar Savings on the Way!</div>
            <div><button className='text-ep-primary p-3 px-3 md:px-6 md:p-3 text-[10.87px] md:text-base rounded-3xl bg-white'>Join the Waitlist <img src={greenRightArrow} className='inline-block ml-2 w-4 h-4' alt="" /></button></div>
        </div>
        <div className='flex-1 w-full md:w-1/2 h-full'>
            <img 
                src={dollarJar} 
                alt="Dollar Jar" 
                className='mix-blend-luminosity w-full h-auto object-contain max-h-[400px] md:max-h-full'
            />
        </div>
      </div>
    </div>
  )
}

export default DollarSavingsOtw