import React from "react";
import "assets/css/business.css";
import { BusinessPageHeader } from "./components/business-page-header";
import { BusinessToolsNeeded } from "./components/business-tools-needed";
import { TrustedByBusinesses } from "./components/trusted-by-businesses";
import { WhyChooseEarnipay } from "./components/why-choose-earnipay";
import { WhatCustomersSay } from "./components/what-customers-say";
import { IntrestedInEarnipay } from "./components/interested-in-earnipay";

export const BusinessPage = () => {
	return (
		<div>
			<BusinessPageHeader />
			<BusinessToolsNeeded />
			<TrustedByBusinesses />
			<WhyChooseEarnipay />
			<WhatCustomersSay />
			<IntrestedInEarnipay />
		</div>
	);
};
