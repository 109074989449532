import React from 'react';
import Maybe from './Maybe';
import businessAccordionInactive from "assets/images/icons/business-accordion-inactive.svg";
import businessAccordionActive from "assets/images/icons/business-accordion-active.svg";
import faqQuestionMark from 'assets/images/icons/faq-question-mark.svg';


const FaqList = ({faqs, toggleFaq, titles, content}) => {
  return (
    <div className='py-20'>
    <div className='container bg-[#F6F8FA] flex flex-col justify-center max-w-[1200px] py-10 relative mx-auto' style={{ backgroundImage: `url(${faqQuestionMark})`, backgroundPosition: 'bottom right', backgroundRepeat: 'no-repeat' }}>
      <div className='flex flex-col items-center'>
        <div className='text-2xl lg:text-[56px] pt-10'>{titles}</div>
        <div className='text-gray-600 text-base lg:text-2xl mt-5'>{content}</div>
      </div>
  
      <div className='w-full flex flex-col items-center mt-10'>
      <div className='border p-5 rounded-xl bg-white shadow-lg w-full md:max-w-[850px]'>
        {faqs.map((faq, index) => (
          <div key={index} className={`w-full ${index < faqs.length - 1 ? 'pb-5' : ''}`}>
            <div className='cursor-pointer py-5 flex justify-between' onClick={() => toggleFaq(index)}>
              <div className={`${faq.isOpen ? "font-bold" : ""} text-left text-ep-primary text-base lg:text-2xl transition-all`}>
                {faq.title}
              </div>
              <div className='' onClick={() => toggleFaq(index)}>
                <Maybe condition={!faq.isOpen}>
                  <img src={businessAccordionActive} onClick={() => toggleFaq(index)} alt="" className='object-contain'/>    
                </Maybe>                            
                <Maybe condition={faq.isOpen}>
                  <img src={businessAccordionInactive} onClick={() => toggleFaq(index)} alt="" className='object-contain'/>    
                </Maybe>                            
              </div>
            </div>
            <Maybe condition={faq.isOpen}>
              <div className='text-[#6F6C8F] pb-3 text-sm lg:text-xl' style={{ width: '100%' }}>{faq.content}</div>
            </Maybe>
            {index < faqs.length - 1 && (
              <div className="mx-auto" style={{ width: '90%', borderTop: '1px solid #e5e7eb' }}></div>
            )}
          </div>
        ))}
      </div>
    </div>
    </div>
  </div>
  )
}

export default FaqList