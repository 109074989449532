import React from "react";
import businessTool1 from "assets/images/business-tool-1.svg";
import businessTool2 from "assets/images/business-tool-2.svg";
import businessTool3 from "assets/images/business-tool-3.svg";
import businessTool4 from "assets/images/business-tool-4.svg";
import businessTool5 from "assets/images/business-tool-5.svg";
import businessTool6 from "assets/images/business-tool-6.svg";

const BUSINESS_TOOLS_NEEDED = [
	businessTool1,
	businessTool2,
	businessTool3,
	businessTool4,
	businessTool5,
	businessTool6,
];

export const BusinessToolsNeeded = () => {
	return (
		<section className="business-tools-section">
			<div className="business-tools-needed-wrapper">
				<h3 className="business-tools-needed-title">
					All The Tools Your Business Needs to Succeed
				</h3>
				<div className="business-tools-slider-wrapper">
					{BUSINESS_TOOLS_NEEDED.map((businessTool, index) => (
						<div
							key={index}
							className="business-tool-wrapper"
						>
							<img
								src={businessTool}
								alt=""
							/>
						</div>
					))}
				</div>
			</div>
		</section>
	);
};
