import React, { useEffect, useState } from "react";
import benefits1 from "assets/images/bgs/odp-benefits-1.svg";
import benefits2 from "assets/images/bgs/odp-benefits-2.svg";
import benefits3 from "assets/images/bgs/odp-benefits-3.svg";
import benefits4 from "assets/images/bgs/odp-benefits-4.svg";
import DynamicHeadTag from "components/misc/DynamicHeadTag";
import googleAnalyticsTrack from "utils/googleAnalyticsTrack";
import onDemandPayHero from "assets/images/bgs/odp-hero.svg";
import OnePlatformForMoney from "components/common/one-platform-money-banner";
import Testimonials from "components/common/testimony";
import { PlayStoreButtons } from "components/common/play-store-buttons";
import greenDiamondBg from "assets/images/bgs/green-diamond-bg.svg";
import orangeDiamondBg from "assets/images/bgs/orange-diamond-bg.svg";
import mobileOrangeDiamondBg from "assets/images/bgs/orange-diamond-mobile.svg";
import { useMediaQuery } from "hooks/useMediaQuery";
import heroWavyVector from "assets/images/bgs/hero-wavy-lines-vector.svg";
import bgLineVector from "assets/images/bg-line-header.svg";
import diamondNetBg from "assets/images/bgs/diamond-net-bg.svg";
import businessOdpGreenBlocks from "assets/images/bgs/business-odp-green-blocks.svg";
import greenRightArrow from "assets/images/icons/green-right-arrow.svg";
import odpCardSteps1 from "assets/images/bgs/personal-odp-step-1.svg";


const ProductsOnDemandPay = () => {

	const isMobile = useMediaQuery('(max-width: 500px)');

	const odpBenefits = [
		{
			image: benefits1,
			title: "Instant Access",
			content:
				"Get immediate access to your earned wages whenever you need them.",
		},
		{
			image: benefits2,
			title: "Financial Flexibility",
			content:
				"Handle unexpected expenses or emergencies without waiting for payday.",
		},
		{
			image: benefits3,
			title: "Reduce Financial Stress",
			content:
				"Avoid late fees and interest charges by paying bills on time.",
		},
		{
			image: benefits4,
			title: "Enhanced Financial Control",
			content:
				"Make better financial decisions with real-time access to your earnings.",
		},
	];

	const odpCardSteps = [
        {
            image: odpCardSteps1,
            title: 'Get Instant Access With On-Demand Pay',
            content: 'Request access from your employer and receive your unique code to unlock earnings anytime.',
        },
        {
            // image: odpCardSteps2,
            title: 'Track Your Earnings Daily',
            content: "See your accrued earnings daily and how much you can access based on your employer's set percentage.",
        },
        {
            // image: odpCardSteps3,
            title: 'Withdraw On Your Terms',
            content: 'Withdraw and spend on the Earnipay app before payday—achieve financial flexibility.',
        },
    ]

    const [activeOdpStep, setActiveOdpStep] = useState(0);

	useEffect(() => {
		googleAnalyticsTrack();
	}, [])

	return (
		<div>

			<DynamicHeadTag
				headerText="On-Demand Pay for Employees | Earnipay"
				headerDescription="Earnipay helps you deliver on-demand pay that gives your employees the financial control they need to be more motivated and happier at work."
			/>

			<div className="2xl:container">
				<img src={bgLineVector} alt="bg line vector" className="bg-line-vector-header" />
			</div>

			<div className="relative flex flex-col items-center text-center page-header-gradient-halfs">
				<div className="hero-main-text powering-business-title">
					Freedom to Choose How You <br /> Get Paid
				</div>
				<div className="hero-sub-text mt-3 access-high-yields">
					Access the salary you've earned as you earn it, anytime, any day.
				</div>
				<div className="pt-4 md:pt-10 first-landing-playstore-btns">
					<PlayStoreButtons />
				</div>

				<img src={heroWavyVector} className="odp-bg-wavy-line-vector" alt="" />

				<div className="mt-16 md:mt-0 mb-16 flex justify-center">
					<img src={onDemandPayHero} className=" w-11/12" alt="" />
				</div>
			</div>

			<div className="relative flex flex-col justify-center mt-12 lg:mt-48 mb-20 bg-center bg-contain w-full h-auto" style={{
				backgroundImage: `url(${isMobile ? mobileOrangeDiamondBg : greenDiamondBg})`,
				backgroundRepeat: 'no-repeat',
			}}>
				<div className="flex justify-center">
					<img src={diamondNetBg} className="absolute top-[-160px] z-[-10]" alt="" />
				</div>
				<div className='flex flex-col items-center'>
					<div className="md:text-3xl lg:text-[56px] leading-none text-center ">Make Any Day Payday</div>
					<div className="orange-diamond-subtext money-that-works-sub-text md:text-2xl text-center max-w-[550px] pt-3">
						Access your hard-earned salary when you need it, not just on payday
					</div>
				</div>
			</div>

			<div className="flex flex-col gap-8 lg:gap-28 p-3 md:p-14 md:pt-0 justify-center mx-auto">
				<div className="container flex flex-col justify-center lg:flex-row gap-8 lg:gap-28 bg-white mx-auto">
					{odpBenefits.slice(0, Math.ceil(odpBenefits.length / 2)).map((benefit, index) => (
						<div key={index} className="border-2 border-gray-200/50 p-4 lg:p-6 rounded-xl w-full lg:w-[444px] lg:h-[230px]">
							<img src={benefit.image} alt={benefit.title} className="mb-4" />
							<div>
								<div className="font-bold text-base lg:text-2xl mb-2">{benefit.title}</div>
								<div className="text-sm lg:text-base text-[#6C6E72]">{benefit.content}</div>
							</div>
						</div>
					))}
				</div>
				<div className="container flex flex-col justify-center lg:flex-row gap-8 lg:gap-28 bg-white mx-auto">
					{odpBenefits.slice(Math.ceil(odpBenefits.length / 2)).map((benefit, index) => (
						<div key={index} className="border-2 border-gray-200/50 p-4 lg:p-6 rounded-xl w-full lg:w-[444px] lg:h-[230px]">
							<img src={benefit.image} alt={benefit.title} className=" mb-4" />
							<div>
								<div className="font-bold text-base lg:text-2xl mb-2">{benefit.title}</div>
								<div className=" text-sm lg:text-base text-[#6C6E72]">{benefit.content}</div>
							</div>
						</div>
					))}
				</div>
			</div>

			<div className="flex flex-col justify-center my-20 mt-28 bg-center bg-contain w-full h-auto" style={{
				backgroundImage: `url(${isMobile ? mobileOrangeDiamondBg : orangeDiamondBg})`,
				backgroundRepeat: 'no-repeat',
			}}>
				<div className='flex flex-col items-center'>
					<div className="md:text-3xl lg:text-[56px] leading-none text-center ">How To Use On-Demand Pay</div>
					<div className="orange-diamond-subtext money-that-works-sub-text md:text-2xl text-center max-w-[600px] pt-3">
						Get access to your money in 3 steps
					</div>
				</div>
			</div>

			{/* <div className="container mt-14 sm:mt-20 lg:mt-28">
				<div className="mt-10 lg:flex bg-[#106368] rounded-xl">
					<div className="w-full lg:w-2/5 flex lg:flex-col lg:pr-20 lg:space-y-5 justify-center p-10">
						{[...Array(3)].map((data, index) => (
							<div key={index} onClick={() => setActiveOdpStep(index)} className={`
								${activeOdpStep === index ? "border-[#B6ECF2]" : "border-transparent lg:border-[#1a99a1]"}
								${activeOdpStep === index ? "text-white" : "text-red-600"}
								w-full lg:mr-16 pl-4 flex justify-between border-b-2 lg:border-b-0 lg:border-l-[2px] rounded-sm cursor-pointer`}
							>
								<div className={`text-base mx-auto lg:mx-0 ${activeOdpStep === index ? "text-white" : "text-[#b6ecf281]"}`}>
									<div className="text-lg font-medium">{odpCardSteps[index].title}</div>
									<div className={`text-ep-primary-light text-sm ${activeOdpStep === index ? "text-white" : "text-[#b6ecf281]"}`}>{odpCardSteps[index].content}</div> 
								</div>
							</div>
						))}
					</div>
					<div className="w-full lg:w-3/5 justify-center sm:flex sm:space-x-12 rounded-xl">
						<div className="mt-8 sm:mt-0 sm:w-1/2 max-h-[400px]">
							<img src={odpCardSteps[activeOdpStep].image} alt={odpCardSteps[activeOdpStep].title} className="w-full h-full object-contain" />
						</div>
					</div>
				</div>
			</div> */}

			<div className="mt-[-100px] md:mt-0">
				<div className="p-3 md:p-10 md:py-0">
					<div className="mt-14 sm:mt-20 lg:mt-[72px] overflow-hidden container">
						<div className=" relative flex flex-col lg:flex-row bg-[#0D545B] rounded-2xl max-w-[1400px] min-h-[592px] w-full p-6 sm:px-10 pt-10  lg:pt-[105.3px] xl:pb-0" 
							style={{
								backgroundImage: `url(${businessOdpGreenBlocks})`, 
								backgroundPosition: 'center',
								backgroundRepeat: 'no-repeat',
								backgroundSize: 'cover',
							}}>
							<div className="relative w-full xl:w-[500px] h-full gap-8 flex flex-col justify-between">
								<div className="absolute h-full bg-[#2E7279] w-1 z-[0]" />
								{[...Array(3)].map((_, index) => (
									<div key={index} onClick={() => setActiveOdpStep(index)} 
										className={`
											relative w-full h-[75px] px-4 flex items-center cursor-pointer transition-all duration-300
											${activeOdpStep === index 
												? "border-l-4 border-[#B6ECF2] text-white" 
												: "border-l-4 border-[#2E7279] text-[#6E989D]"
											}
										`}
									>
										<div className="w-full">
											<div className="text-sm sm:text-lg xl:text-2xl xl:leading-[28.8px] font-medium text-left">{odpCardSteps[index].title}</div>
											<div className={`text-xs sm:text-sm xl:text-base text-left 
												${activeOdpStep === index ? "text-[#9EBBBD]" : "text-[#477D82]"}`}>
												{odpCardSteps[index].content}
											</div> 
										</div>
									</div>
								))}
							</div>
								<div className="absolute bottom-[97.77px] w-full lg:flex justify-start pt-10 hidden">
									<button className="px-8 py-3 text-[#0D545B] bg-white rounded-full hover:bg-gray-200 text-sm xl:text-xl transition-colors duration-300 flex items-center">
										<a href="https://business.earnipay.com/onboarding/get-started">Get The App</a>  
										<img src={greenRightArrow} className='ml-2' alt="Right Arrow" />
									</button>
								</div>
								<div className="my-10 lg:hidden">
									<button className="px-8 py-3 text-[#0D545B] bg-white rounded-full hover:bg-gray-200 text-sm xl:text-xl transition-colors duration-300 flex items-center">
										<a href="https://business.earnipay.com/onboarding/get-started">Get The App</a>  
										<img src={greenRightArrow} className='ml-2' alt="Right Arrow" />
									</button>
								</div>
							<div className="w-full xl:w-[650px] flex justify-center items-center -mb-6 sm:-mb-28 xl:-mt-6 xl:ml-auto">
								<div className="w-full overflow-hidden">
									<img 
										src={odpCardSteps[activeOdpStep].image} 
										alt={odpCardSteps[activeOdpStep].title} 
										className="w-full h-[300px] sm:h-[500px] md:object-contain rounded-t-3xl transition-transform duration-300 ease-in-out transform hover:scale-95" 
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="flex flex-col justify-center mt-20 my-10 md:my-20 md:mb-[-100px] bg-center bg-contain w-full h-auto" style={{
				backgroundImage: `url(${isMobile ? mobileOrangeDiamondBg : orangeDiamondBg})`,
				backgroundRepeat: 'no-repeat',
			}}>
				<div className='flex flex-col items-center'>
					<div className="md:text-3xl lg:text-[56px] leading-none text-center ">Hear From Our Users</div>
					<div className="orange-diamond-subtext money-that-works-sub-text md:text-2xl text-center max-w-[600px] pt-3">
						Discover how On-Demand Pay has transformed their financial lives.
					</div>
				</div>
			</div>

			<Testimonials />

			<div className="mt-20 lg:mt-[-70px]">
				<OnePlatformForMoney />
			</div>
		
		</div>
	);
};

export default ProductsOnDemandPay;
