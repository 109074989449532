import React from "react";
import createBtnUnionBg from "assets/images/cta/create-account-btn-union.svg";
import slantArrowRight from "assets/images/cta/slant-arrow-right.svg";
import createBtnUnionBgMobile from "assets/images/cta/create-account-btn-union-mobile.svg";
import slantArrowRightMobile from "assets/images/cta/slant-arrow-right-mobile.svg";
import businessHeaderGridBg from "assets/images/business-header-grid-bg.svg";
import businessHeaderGridBgMobile from "assets/images/business-header-grid-bg-mobile.svg";
// import businessHeaderImg from "assets/images/business-header-img.svg";
import businessHeaderImg from "assets/images/dashboard-header-image.png";
import { Link } from "react-router-dom";
import { useMediaQuery } from "hooks/useMediaQuery";
import businessHeaderImageMobile1 from "assets/images/business-header-mobile-1.png";

export const BusinessPageHeader = () => {
	const isMobile = useMediaQuery("(max-width: 768px)");

	return (
		<div className="business-page-header-wrapper">
			<div className="business-page-header-text-wrapper">
				<h2 className="business-page-title">
					Powering Businesses for Growth
				</h2>
				<p className="business-page-header-text">
					Access credit, high-yield accounts, and all the tools your
					business needs to succeed on one platform.
				</p>
				<div className="business-page-header-cta">
					<Link
						to={{
							pathname:
								"https://business.earnipay.com/onboarding/get-started",
						}}
						target="_blank"
						rel="noreferrer"
					>
						<img
							className="business-page-header-union"
							src={
								isMobile
									? createBtnUnionBgMobile
									: createBtnUnionBg
							}
							alt=""
						/>
						<span>Create A Business Account</span>
						<img
							className="business-page-header-slant-arrow-right"
							src={
								isMobile
									? slantArrowRightMobile
									: slantArrowRight
							}
							alt=""
						/>
					</Link>
				</div>
			</div>
			<div className="business-page-header-img-wrapper">
				<img
					src={
						isMobile
							? businessHeaderImageMobile1
							: businessHeaderImg
					}
					alt=""
					className="business-page-header-img"
				/>
			</div>
			<img
				src={
					isMobile ? businessHeaderGridBgMobile : businessHeaderGridBg
				}
				alt=""
				className="business-header-grid-bg"
			/>
		</div>
	);
};
