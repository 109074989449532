import React, { useState, useRef, useMemo, useEffect } from "react";
import logo from "assets/images/icons/earnipay-logo.svg";
import logoMobile from "assets/images/icons/earnipay-logo-mobile.svg";
import caretDownBlack from "assets/images/icons/caret-down-black.svg";
import caretRightWhite from "assets/images/icons/caret-right-white.svg";
// import DropdownNew from "utils/dropdown-new";
// import DropdownNewBlock from "utils/dropdown-new-block";
import { Link, /* NavLink, */ useLocation, useHistory } from "react-router-dom";
// import facebook from "assets/images/social/facebook-icon.svg";
// import twitter from "assets/images/social/twitter-icon.svg";
// import instagram from "assets/images/social/instagram-icon.svg";
// import linkedin from "assets/images/social/linkedin-icon.svg";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMediaQuery } from "hooks/useMediaQuery";
import { NavBarDropdown } from "./nav-bar-dropdown";
import CreditIcon from "assets/images/credit-icon.svg";
import PayrollIcon from "assets/images/payroll-icon.svg";
import AccountsIcon from "assets/images/accounts-icon.svg";
import OdpIcon from "assets/images/odp-icon.svg";
import PaymentsIcon from "assets/images/payments-icon.svg";
import LoansIcon from "assets/images/loans-icon.svg";
import RewardsIcon from "assets/images/rewards-icon.svg";
import SavingsIcon from "assets/images/savings-icon.svg";
import "assets/css/navbar.css";

const Header = () => {
	const location = useLocation();
	const history = useHistory();
	const isMobile = useMediaQuery("(max-width: 900px)");
	const [mobileNav, setMobileNav] = useState(false);
	const personalNavDropdownRef = useRef(null);
	const [mobileNavDropdownState, setMobileNavDropdownState] = useState({
		business: false,
		personal: false,
	});
	const [menuOptionClicked, setMenuOptionClicked] = useState(false);

	const handleToggleMobileNavDropdown = (key) => {
		setMobileNavDropdownState({
			...mobileNavDropdownState,
			[key]: !mobileNavDropdownState[key],
		});
	};

	const handleTogglePersonalNavDropdown = () => {
		if (personalNavDropdownRef.current.handleToggleDropdown) {
			personalNavDropdownRef.current.handleToggleDropdown();
		}
	};

	const toggleMobileNav = () => {
		const mobileMenu = document.querySelector(".mobile-menu");
		const navIcon = document.querySelector("#nav-icon");

		if (!mobileNav) {
			mobileMenu?.classList.remove("hidden");
			navIcon?.classList.add("open");
		} else {
			mobileMenu?.classList.add("hidden");
			navIcon?.classList.remove("open");
		}

		setMobileNav(!mobileNav);
	};

	const handleNavigate = (path) => {
		history.push(path);
	};

	const businessNavbarDropdownOptions = [
		{
			icon: CreditIcon,
			title: "Credit",
			text: "Customized Credit solutions for business growth",
			handleClick: () => {
				setMenuOptionClicked(true);
				handleNavigate("/business/credit");
			},
		},
		{
			icon: PayrollIcon,
			title: "Payroll",
			text: "Seamless Payroll Management for your team",
			handleClick: () => {
				setMenuOptionClicked(true);
				handleNavigate("/business/payroll");
			},
		},
		{
			icon: AccountsIcon,
			title: "Accounts",
			text: "Manage Funds with High-Yield Sub-Account",
			handleClick: () => {
				setMenuOptionClicked(true);
				handleNavigate("/business/account");
			},
		},
		{
			icon: OdpIcon,
			title: "On-Demand Pay",
			text: "Give employees control over their earnings",
			handleClick: () => {
				setMenuOptionClicked(true);
				handleNavigate("/business/odp");
			},
		},
		{
			icon: PaymentsIcon,
			title: "Payments",
			text: "Make transfers and pay bills reliably",
			handleClick: () => {
				setMenuOptionClicked(true);
				handleNavigate("/business/payment");
			},
		},
	];

	const personalNavbarDropdownOptions = [
		{
			icon: LoansIcon,
			title: "Loans",
			text: "Personalized loans tailored to your Needs",
			handleClick: () => {
				setMenuOptionClicked(true);
				handleNavigate("/personal/credit");
			},
		},
		{
			icon: RewardsIcon,
			title: "Rewards",
			text: "Get rewarded for transactions",
			handleClick: () => {
				setMenuOptionClicked(true);
				handleNavigate("/personal/rewards");
			},
		},
		{
			icon: SavingsIcon,
			title: "Savings",
			text: "Grow your savings with personalized plans",
			handleClick: () => {
				setMenuOptionClicked(true);
				handleNavigate("/personal/savings");
			},
		},
		{
			icon: OdpIcon,
			title: "On-Demand Pay",
			text: "Instant access to your earnings, anytime",
			handleClick: () => {
				setMenuOptionClicked(true);
				handleNavigate("/personal/on-demand-pay");
			},
		},
	];

	const dropdownNavbarOptions = useMemo(() => {
		if (location.pathname.startsWith("/business")) {
			return businessNavbarDropdownOptions;
		}

		if (location.pathname.startsWith("/personal")) {
			return personalNavbarDropdownOptions;
		}

		return businessNavbarDropdownOptions;
		// eslint-disable-next-line
	}, [location]);

	const signInLink = useMemo(() => {
		if (location.pathname.startsWith("/business")) {
			return "https://business.earnipay.com/onboarding/get-started";
		}

		if (location.pathname.startsWith("/personal")) {
			return "https://onelink.to/vazvk5";
		}
	}, [location]);

	useEffect(() => {
		if (!menuOptionClicked) return;
		if (isMobile) {
			toggleMobileNav();
			setMenuOptionClicked(false);
		} else {
			handleTogglePersonalNavDropdown();
			setMenuOptionClicked(false);
		}
		// eslint-disable-next-line
	}, [menuOptionClicked, isMobile]);

	return (
		<React.Fragment>
			{/* <HeaderText /> */}

			{/* flex landing-page-header-gradient-full bg-ep-primary-light border-b mb-0 */}
			<div className="font-aeonik fixed z-50 top-0 left-0 right-0 header-main-wrapper">
				<nav className="website-nav">
					<div className="website-nav-inner">
						<div className="logo-and-acct-type">
							<div className="logo-wrapper">
								<Link
									to="/business"
									className=""
								>
									<img
										src={isMobile ? logoMobile : logo}
										alt="Earnipay logo"
										className=""
									/>
								</Link>
							</div>
							<ul className="acct-type-wrapper">
								<li
									className={`${
										location.pathname === "/business"
											? "website-nav-link-active"
											: ""
									}`}
								>
									<Link to="/business">Business</Link>
								</li>
								<li
									className={`${
										location.pathname === "/personal"
											? "website-nav-link-active"
											: ""
									}`}
								>
									<Link to="/personal">Personal</Link>
								</li>
							</ul>
						</div>
						<div className="other-nav-menu-and-sign-in-btn">
							<ul>
								<div className="other-nav-menu-wrapper">
									<li>
										<NavBarDropdown
											ref={personalNavDropdownRef}
											navbarDropdownContainerClassName="navbar-dropdown-container"
											dropdownTrigger={
												<div
													className="flex items-center"
													onClick={
														handleTogglePersonalNavDropdown
													}
												>
													<span className="">
														Product
													</span>
													<img
														src={caretDownBlack}
														className=""
														alt=""
													/>
												</div>
											}
											dropdownContent={
												<>
													{dropdownNavbarOptions.map(
														({
															icon,
															title,
															text,
															handleClick,
														}) => (
															<div
																className="nav-dropdown-item"
																onClick={
																	handleClick
																}
															>
																<div className="nav-dropdown-item-img-wrapper">
																	<img
																		src={
																			icon
																		}
																		alt={`${title}`}
																	/>
																</div>
																<div className="nav-dropdown-item-text-wrapper">
																	<h5 className="nav-dropdown-item-title">
																		{title}
																	</h5>
																	<p className="nav-dropdown-item-text">
																		{text}
																	</p>
																</div>
															</div>
														)
													)}
												</>
											}
										/>
									</li>
									<li
										className={`${
											location.pathname === "/company"
												? "website-nav-link-active"
												: ""
										}`}
									>
										<Link to="/company">Company</Link>
									</li>
								</div>
								<li>
									<button className="nav-sign-in-btn">
										<Link
											to={{
												pathname: signInLink,
											}}
											target="_blank"
											rel="noreferrer"
										>
											<span>Sign In</span>
											<img
												src={caretRightWhite}
												className=""
												alt=""
											/>
										</Link>
									</button>
								</li>
							</ul>
							{/* Mobile toggle hamburger */}
							<div className="h-9 hamburger-menu flex">
								<div
									onClick={toggleMobileNav}
									id="nav-icon"
								>
									<span></span>
									<span></span>
									<span></span>
									<span></span>
								</div>
							</div>
						</div>
					</div>
				</nav>
			</div>

			{/* Mobile */}
			<div className="mt-10 mobile-menu hidden bg-blur w-full h-full fixed z-40 inset-0 overflow-auto overscroll-contain bg-white">
				<div className="w-full h-full flex bg-white bg-opacity-50">
					<div className="my-auto">
						<div className="w-screen">
							<div className="mobile-nav-wrapper">
								<div className="mobile-nav-item">
									<div className="flex items-center mobile-nav-menu-item">
										<span className="">
											<Link
												to="/business"
												onClick={toggleMobileNav}
											>
												Business
											</Link>
										</span>
										<img
											src={caretDownBlack}
											className=""
											alt=""
											onClick={() =>
												handleToggleMobileNavDropdown(
													"business"
												)
											}
										/>
									</div>
									<div
										className={`mobile-nav-dropdown-container ${
											!mobileNavDropdownState.business
												? "hidden"
												: ""
										}`}
									>
										{mobileNavDropdownState.business && (
											<div className="mobile-nav-dropdown-wrapper">
												{businessNavbarDropdownOptions.map(
													({
														icon,
														title,
														text,
														handleClick,
													}) => (
														<div
															className="nav-dropdown-item"
															onClick={
																handleClick
															}
														>
															<div className="nav-dropdown-item-img-wrapper">
																<img
																	src={icon}
																	alt={`${title}`}
																/>
															</div>
															<div className="nav-dropdown-item-text-wrapper">
																<h5 className="nav-dropdown-item-title">
																	{title}
																</h5>
																<p className="nav-dropdown-item-text">
																	{text}
																</p>
															</div>
														</div>
													)
												)}
											</div>
										)}
									</div>
								</div>
								<div className="mobile-nav-item">
									<div className="flex items-center mobile-nav-menu-item">
										<span className="">
											<Link
												to="/personal"
												onClick={toggleMobileNav}
											>
												Personal
											</Link>
										</span>
										<img
											src={caretDownBlack}
											className=""
											alt=""
											onClick={() =>
												handleToggleMobileNavDropdown(
													"personal"
												)
											}
										/>
									</div>
									<div
										className={`mobile-nav-dropdown-container ${
											!mobileNavDropdownState.personal
												? "hidden"
												: ""
										}`}
									>
										{mobileNavDropdownState.personal && (
											<div className="mobile-nav-dropdown-wrapper">
												{personalNavbarDropdownOptions.map(
													({
														icon,
														title,
														text,
														handleClick,
													}) => (
														<div
															className="nav-dropdown-item"
															onClick={
																handleClick
															}
														>
															<div className="nav-dropdown-item-img-wrapper">
																<img
																	src={icon}
																	alt={`${title}`}
																/>
															</div>
															<div className="nav-dropdown-item-text-wrapper">
																<h5 className="nav-dropdown-item-title">
																	{title}
																</h5>
																<p className="nav-dropdown-item-text">
																	{text}
																</p>
															</div>
														</div>
													)
												)}
											</div>
										)}
									</div>
								</div>
								<div className="mobile-nav-item">
									<div className="flex items-center mobile-nav-menu-item">
										<Link
											to="/company"
											onClick={toggleMobileNav}
										>
											Company
										</Link>
									</div>
								</div>
								<div className="mobile-nav-item mobile-nav-menu-item">
									<button className="nav-sign-in-btn">
										<Link
											to={{
												pathname: signInLink,
											}}
											target="_blank"
											rel="noreferrer"
										>
											<span>Sign In</span>
											<img
												src={caretRightWhite}
												className=""
												alt=""
											/>
										</Link>
									</button>
								</div>
							</div>
							{/* <div className="header-links-mobile">
								<Link
									to="/business"
									onClick={toggleMobileNav}
								>
									Business
								</Link>
							</div>
							<div className="header-links-mobile">
								<Link
									to="/personal"
									onClick={toggleMobileNav}
								>
									Personal
								</Link>
							</div>

							<DropdownNewBlock
								as="div"
								className="w-full"
							>
								<div className="header-links-mobile">
									<div className="block">
										Product
										<FontAwesomeIcon
											icon="angle-down"
											className="font-bold ml-1"
										/>
									</div>
								</div>
								<div>
									<Link
										to="/personal/credit"
										onClick={toggleMobileNav}
										className="dropdown-item-large"
									>
										Loans
									</Link>
								</div>
							</DropdownNewBlock>

							<div className="header-links-mobile">
								<Link
									to="/company"
									onClick={toggleMobileNav}
								>
									Company
								</Link>
							</div>

							<div className="header-links-mobile">
								<Link
									to={{
										pathname:
											"https://business.earnipay.com/onboarding/get-started",
									}}
									target="_blank"
									rel="noreferrer"
									onClick={toggleMobileNav}
								>
									Signup
								</Link>
							</div>
							<div className="flex mt-10">
								<div className="mx-auto flex space-x-6">
									<Link
										to={{
											pathname:
												"https://www.facebook.com/Earnipay",
										}}
										target="_blank"
										onClick={toggleMobileNav}
										className="header-links-social"
									>
										<img
											src={facebook}
											alt="facebook"
										/>
									</Link>
									<Link
										to={{
											pathname:
												"https://www.twitter.com/earnipay",
										}}
										target="_blank"
										onClick={toggleMobileNav}
										className="header-links-social"
									>
										<img
											src={twitter}
											alt="instagram"
										/>
									</Link>
									<Link
										to={{
											pathname:
												"https://www.instagram.com/getearnipay/",
										}}
										target="_blank"
										onClick={toggleMobileNav}
										className="header-links-social"
									>
										<img
											src={instagram}
											alt="instagram"
										/>
									</Link>
									<Link
										to={{
											pathname:
												"https://www.linkedin.com/company/earnipay",
										}}
										target="_blank"
										onClick={toggleMobileNav}
										className="header-links-social"
									>
										<img
											src={linkedin}
											alt="linkedin"
										/>
									</Link>
								</div>
							</div> */}
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Header;
