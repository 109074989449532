import DynamicHeadTag from "components/misc/DynamicHeadTag";
import { useEffect } from "react";
import googleAnalyticsTrack from "utils/googleAnalyticsTrack";
// import appStoreBlack from "assets/images/icons/app-store-black.svg";
// import playStoreBlack from "assets/images/icons/play-store-black.svg";
import savingsHeroImg from "assets/images/bgs/new-savings-hero.svg";
import flexSavingsImg from "assets/images/bgs/flex-savings-img.svg";
// import arrowWhite from "assets/images/icons/arrow-right-white.svg";
// import savingsForYouImg from "assets/images/bgs/savings-for-you-frame.svg";
import savingsFlexImg from "assets/images/bgs/savings-flex-img.svg";
import goalSavingsImg from "assets/images/bgs/goal-savings-img.svg";
import greenDiamondBg from "assets/images/bgs/green-diamond-bg.svg";
import greenDiamondBgMobile from "assets/images/bgs/green-diamond-bg-mobile.svg";
import OnePlatformForMoney from "components/common/one-platform-money-banner";
import personalSavingsNetworkImg from "assets/images/icons/personal-savings-network-icon.svg";
import flexSavingsRightLine from "assets/images/bgs/flex-savings-right-line.svg";
import flexSavingsLeftLine from "assets/images/bgs/flex-savings-left-line.svg";
import { PlayStoreButtons } from "components/common/play-store-buttons";
import DollarSavingsOtw from "components/common/dollar-jar";
import { useMediaQuery } from "hooks/useMediaQuery";
import heroWavyVector from "assets/images/bgs/hero-wavy-lines-vector.svg";
import bgLineVector from "assets/images/bg-line-header.svg";
import shortWhiteRightArrow from "assets/images/icons/short-right-white-arrow.svg";
import diamondNetBg from "assets/images/bgs/diamond-net-bg.svg";


const ProductsSavings = () => {

	const isMobile = useMediaQuery('(max-width: 500px)');

	useEffect(() => {
		googleAnalyticsTrack();
	}, [])

	return (
		<div>

			<DynamicHeadTag
				headerText="Grow Your Savings with Earnipay - Start Saving Today!"
				headerDescription="Discover the power of saving with Earnipay. Earn high-interest rates and achieve your money goals effortlessly. Start saving today and secure your financial future."
			/>			

				<div className="2xl:container">
					<img src={bgLineVector} alt="bg line vector" className="bg-line-vector-header" />
				</div>

				<div className="relative flex flex-col page-header-gradient-half items-center text-center">
					<div className="hero-main-text powering-business-title">
						Put Your Money To Work
					</div>
					<div className="hero-sub-text mt-6 access-high-yields">
						Unlock higher returns and watch your money multiply with interest paid daily at market leading rates.
					</div>
					<div className="pt-4 md:pt-10 first-landing-playstore-btns">
						<PlayStoreButtons />
					</div>

					<div className="mt-14 relative">
						<img src={savingsHeroImg} className="" alt="" />
					</div>
					
					<img src={heroWavyVector} className="absolute top-[-20%] z-[-10]" alt="" />
				</div>

				<div className="p-3 md:p-20 flex justify-center ">
					<div className="relative flex flex-col lg:flex-row space-x-5 bg-[#F6F8FA] rounded-2xl h-auto mt-20 max-w-[1200px] lg:h-[515px]">
						<div className="flex-1 p-5 mx-auto md:mt-8 md:ml-8">
							<div className="text-2xl md:text-3xl lg:text-5xl lg:leading-[57.6px]">Make Your Money Work As Hard As You</div>
							<div className="text-gray-600 mt-2 text-sm md:text-xl">Earn market leading returns on flexible and fixed savings.</div>
							<div className="relative btn bg-[#095961] green-btn-outline rounded-full mt-8 mb-10 px-5 md:px-12 text-white p-3 md:p-4 text-xs md:text-base z-10">Get Started <img src={shortWhiteRightArrow} alt="" className="pl-2"/></div>
						</div>
						<img src={personalSavingsNetworkImg} className="hidden lg:flex absolute savings-antenna-images grayscale" alt="" />
						<div className="flex-1 flex justify-center">
							<img src={flexSavingsImg} className="w-3/5 lg:w-full mr-10" alt="" />
						</div>
					</div>
				</div>

				<div className="relative flex flex-col justify-center mt-36 bg-center bg-contain w-full h-auto" style={{
					backgroundImage: `url(${isMobile ? greenDiamondBgMobile : greenDiamondBg})`,
					backgroundRepeat: 'no-repeat',
				}}>
					<div className="flex justify-center">
						<img src={diamondNetBg} className="absolute top-[-150px] z-[-10]" alt="" />
					</div>
					
					<div className='flex flex-col items-center'>
						<div className="md:text-3xl lg:text-[56px] leading-none text-center ">Savings Tailored Just For You</div>
						<div className="text-gray-600 money-that-works-sub-text md:text-2xl text-center max-w-[600px] pt-3 orange-diamond-subtext">
						Get started in seconds
						</div>
					</div>
				</div>

				<div className="flex justify-center">
					<div className="flex flex-col lg:flex-row items-center space-y-8 lg:space-y-0 lg:space-x-28 mt-20 p-8">
						<div className="bg-[#F6F8FA] rounded-3xl p-5 pb-0 lg:pt-10 lg:px-10 space-y-4 max-h-[519px] max-w-[536px] h-auto w-full flex flex-col" 
							style={{
								backgroundImage: `url(${flexSavingsLeftLine})`, 
								backgroundPosition: 'bottom', 
								backgroundRepeat: 'no-repeat', 
								backgroundSize: 'contain'
								}}>
							<div className="text-2xl font-medium">Flex</div>
							<div className="text-gray-600 text-sm w-full">A savings plan that puts your spare change to work with no commitments or breaking fees, only consistent daily returns.</div>
							<div className="flex-grow flex items-end justify-center overflow-hidden">
								<img src={savingsFlexImg} className="max-w-full max-h-full object-contain" alt="Flex Savings" />
							</div>
						</div>
						<div className="bg-[#F6F8FA] rounded-3xl p-5 pb-0 lg:px-10 lg:pt-10 space-y-4 max-h-[519px] max-w-[536px] h-auto w-full flex flex-col" 
							style={{
								backgroundImage: `url(${flexSavingsRightLine})`, 
								backgroundPosition: 'bottom', 
								backgroundRepeat: 'no-repeat', 
								backgroundSize: 'contain'
							}}>
							<div className="text-2xl font-medium">Goal</div>
							<div className="text-gray-600 text-sm w-full">Ambitious goals need your commitment, and goal savings earn you higher returns over a fixed period.</div>
							<div className="flex-grow flex items-end justify-center overflow-hidden">
								<img src={goalSavingsImg} className="max-w-full max-h-full object-contain" alt="Goal Savings" />
							</div>
						</div>
					</div>
				</div>

				<div className="p-4 md:px-8 pb-0 mx-auto mt-24 ">
					<DollarSavingsOtw />
				</div>

				<OnePlatformForMoney />
			
		</div>
	);
};

export default ProductsSavings