import businessPaymentHeroImg from '../../assets/images/bgs/business-payment-hero-img.svg';
import greenButton from '../../assets/images/icons/green-button-revamp.svg';
import greenDiamondBg from "assets/images/bgs/green-diamond-bg.svg";
// import paymentGetStartedBtn from "assets/images/bgs/payroll-get-started-btn.svg";
import { ReactComponent as BusinessPaymentCard1} from "assets/images/bgs/business-payment-card-1.svg";
import businessPaymentCard2 from "assets/images/bgs/business-payment-card-2.svg";
import businessPaymentCard3 from "assets/images/bgs/business-payment-card-3.svg";
import businessPaymentCard4 from "assets/images/bgs/business-payment-card-4.svg";
import greyCheckCircle from "assets/images/icons/business-payment-grey-check-circle.svg";
import CtaSection from "components/common/cta-green";
import { useMediaQuery } from 'hooks/useMediaQuery';
import greenDiamondBgMobile from "assets/images/bgs/green-diamond-bg-mobile.svg";
import businessPaymentVector from "assets/images/bgs/business-payment-vector.svg";
import DynamicHeadTag from 'components/misc/DynamicHeadTag';
import { Link } from "react-router-dom";
// import greenPillButtonBg from "assets/images/icons/green-pill-button.svg";
import shortWhiteArrow from "assets/images/icons/short-right-white-arrow.svg";


const BusinessPayment = () => {

  const isMobile = useMediaQuery('(max-width: 500px)');

  return (
      <div>
        <DynamicHeadTag
            headerText="Bulk Data, Airtime and Bill Payments"
            headerDescription=" Manage transfers, airtime, data, and bill payments with precision using one platform. Pay multiple beneficiaries, settle invoices, and handle recurring payments securely and efficiently."
        />

        <div style={{backgroundImage: `url(${businessPaymentVector})`, backgroundSize: '80%', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}>

        <div className="flex flex-col items-center text-center page-header-gradient-half z-10">
          <div className="hero-main-text powering-business-title">
            Make Payments With Precision, Everytime
          </div>
          <div className="text-gray-600 mt-5 max-w-[850px] hero-sub-text access-high-yields">
            Control, schedule, and automate payments, whether to one recipient or a thousand.
          </div>
                  
          <div className='pt-10'>
            <div className="relative w-60 md:w-[320px] md:h-[52px] cursor-pointer"> 
              <img src={greenButton} alt="" className=" w-full h-full object-cover" />
              <div className="absolute left-16 md:left-24 top-1/4 bottom-0 text-white text-sm md:text-base">                  
                  <Link
                      to={{
                          pathname:
                          "https://business.earnipay.com/onboarding/get-started",
                      }}
                      target="_blank"
                      rel="noreferrer"
                  >
                      Get Started
                  </Link>  
              </div>
            </div>
          </div>
          </div>

          <div className="mt-8 mb-16 flex justify-center">
            <img src={businessPaymentHeroImg} className="h-1/2w w-[90%]" alt="" />
          </div>
        </div>

        <div className="flex flex-col justify-center my-20 bg-center bg-contain w-full h-auto" style={{
          backgroundImage: `url(${isMobile ? greenDiamondBgMobile : greenDiamondBg})`,
          backgroundRepeat: 'no-repeat',
          }}>
          <div className='flex flex-col items-center'>
            <div className="md:text-3xl lg:text-[56px] leading-none text-center ">Transfers That Work</div>
            <div className="text-[#6C6E72] money-that-works-sub-text md:text-2xl text-center max-w-[600px] pt-5">
            Send money with confidence. Experience secure and efficient money transfer today.
            </div>
          </div>
        </div>

        <div className='md:p-5 container'>
          <div className='rounded-3xl border p-2'>
            <div className='flex flex-col'>
              <div className='flex flex-col lg:flex-row space-y-5 lg:space-x-5 flex-1 h-full lg:max-h-[650px] max-w-full p-2 md:p-6'>
                <div className='flex-1 w-full lg:w-[50%]'>
                <BusinessPaymentCard1 className='w-full business-payments-card-1 h-full'/>
                </div>
                <div className='flex-1 w-full lg:w-[50%] mt-0 md:mt-4 md:ml-2'>
                  <div className='space-y-3 multiple-payments-container p-2'>
                    <div className="text-[32px] xl:text-[56px] leading-tight multiple-payments">Multiple Payments Fast And Easy</div>
                    <div className="text-gray-600 leading-tight py-2 multiple-payments-content">Simplify your payments with Earnipay. Experience seamless, efficient, and secure transactions today</div>
                    <div className='space-y-2 text-gray-600'>
                      <div className='flex space-x-3'>
                        <img src={greyCheckCircle} alt="" />
                        <div className='text-[17px] multiple-payments-checklist'>Pay from existing beneficiaries</div>
                      </div>
                      <div className='flex space-x-3'>
                        <img src={greyCheckCircle} alt="" />
                        <div className='text-[17px] multiple-payments-checklist'>Intuitive Interface</div>
                      </div>
                      <div className="pt-4 cursor-pointer ">
                          <Link
                                to={{
                                    pathname:
                                    "https://business.earnipay.com/onboarding/get-started",
                                  }}
                                target="_blank"
                                rel="noreferrer"
                            >
                              <button className='get-started-button text-sm md:text-base px-6 btn rounded-3xl'>Get Started <img src={shortWhiteArrow} className='pl-5' alt="" /></button>
                            </Link>  
                      </div>
                      
                      <div className=''>
                        <img src={businessPaymentCard2} className='multiple-payments w-full h-auto lg:hiddens' alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='py-10 xl:my-10'>
                <hr className='w-11/12 mx-auto payment-hr'/>
              </div>

              <div className='flex-1 flex flex-col md:flex-row md:space-x-5 max-w-full p-2 md:p-6 md:pt-0'>                
                <div className='flex-1 space-y-4'>
                  <div className='space-y-3 md:space-y-0 multiple-payments-container p-2'>
                    <div className='text-[32px] lg:text-[56px] leading-tight multiple-payments'>Seamless Bulk Payments</div>
                    <div className='text-gray-600 py-3 multiple-payments-content'>Easily manage large-scale transactions. Process bulk payments quickly and accurately to save time and reduce manual errors.</div>
                    <div className="mt-6 cursor-pointer pt-2">
                      <Link
                          to={{
                              pathname:
                              "https://business.earnipay.com/onboarding/get-started",
                            }}
                          target="_blank"
                          rel="noreferrer"
                      >
                        <button className='get-started-button px-6 btn text-sm md:text-base rounded-3xl'>Get Started <img src={shortWhiteArrow} className='pl-5' alt="" /></button>                              
                      </Link>
                    </div>
                  </div>
                  <div>
                    <img src={businessPaymentCard3} className='w-[530px]' alt="" />
                  </div>
                </div>

                <div className='flex-1 mt-5'>
                  <div className='p-1 h-full'>
                    <div className='bg-[#F6F8FA] border border-[#D0D5DD] rounded-xl p-3 h-full flex flex-col space-y-20'>
                      <img src={businessPaymentCard4} className='w-full max-w-[600px] mx-auto' alt="" />
                      <div className='space-y-3 p-2 multiple-payments-container'>
                        <div className='text-3xl md:text-4xl multiple-payments'>Split Transactions</div>
                        <div className='text-gray-600 multiple-payments-content'>Effortlessly divide payments among multiple beneficiaries. Ensure precise and efficient fund distribution with just a few clicks.</div>
                      </div>
                    </div>
                  </div>
                </div>                
              </div>
            </div>
          </div>
        </div>

        <div className='pt-20'>
          <CtaSection />
        </div>

       
    </div>
  )
}

export default BusinessPayment