import React from "react";
import { Helmet } from "react-helmet";

const DynamicHeadTag = ({ headerText, headerDescription }) => {

	const title = headerText || "Earnipay";
	const description = headerDescription || "Earnipay empowers businesses to overcome their finance and people operations challenges and improve their employees’ financial wellness";
	
	return (
		<Helmet>
			<title>{title}</title>
			<meta name="description" content={description} data-rh="true"/>
			<meta itemprop="description" content={description} data-rh="true"/>
			
			<meta property="og:url" content="https://earnipay.com/" />
			<meta property="og:type" content="article" />
			<meta property="og:title" content={title} data-rh="true" />
			<meta property="og:description" content={description} data-rh="true" />

			<meta property="og:image" content="https://ep-mediafiles.s3.eu-west-1.amazonaws.com/Earnipay_website_png_1704309003186.png" />
			<meta property="og:image:url" content="https://ep-mediafiles.s3.eu-west-1.amazonaws.com/Earnipay_website_png_1704309003186.png" />
			<meta property="og:image:secure_url" content="https://ep-mediafiles.s3.eu-west-1.amazonaws.com/Earnipay_website_png_1704309003186.png" />
		</Helmet>
	);
};

export default DynamicHeadTag;
