// import heroBackgroundImage from "assets/images/bgs/business-payroll-line-bg.svg";
import DynamicHeadTag from "components/misc/DynamicHeadTag";
import businnessPayrollMainImg from "assets/images/bgs/business-payroll-main-img.svg";
import greenDiamondBg from "assets/images/bgs/green-diamond-bg.svg";
import { useMediaQuery } from 'hooks/useMediaQuery';
import greenDiamondBgMobile from "assets/images/bgs/green-diamond-bg-mobile.svg";
import payrollProcessImg1 from "assets/images/bgs/payroll-process-img-1.svg";
import payrollProcessImg2 from "assets/images/bgs/payroll-process-img-2.svg";
import payrollProcessImg3 from "assets/images/bgs/payroll-process-img-3.svg";
import payrollProcessImg4 from "assets/images/bgs/payroll-process-img-4.svg";
import payrollBenefitsImg1 from "assets/images/bgs/payroll-benefits-img-1.svg";
import payrollBenefitsImg2 from "assets/images/bgs/payroll-benefits-img-2.svg";
import payrollBenefitsImg3 from "assets/images/bgs/payroll-benefits-img-3.svg";
import payrollBenefitsImg4 from "assets/images/bgs/payroll-benefits-img-4.svg";
import payrollSubGreenDiamond from "assets/images/bgs/payroll-subscription-green-diamond.svg";
import payrollGreenCircle from "assets/images/icons/payroll-green-check-circle.svg";
// import payrollGetStartedBtn from "assets/images/bgs/payroll-get-started-btn.svg";
import CtaSection from "components/common/cta-green";
// import greenButton from '../../assets/images/icons/green-button-revamp.svg';
import payrollGreenHand from 'assets/images/icons/payroll-green-hand.svg';
import bgLineVector from "assets/images/bg-line-header.svg";
import diamondNetBg from "assets/images/bgs/diamond-net-bg.svg";
// import getStartedRevampBtn from "assets/images/icons/get-started-revamp.svg";
import { Link } from "react-router-dom";
import shortWhiteArrow from "assets/images/icons/short-right-white-arrow.svg";
import greenButton from '../../assets/images/icons/green-button-revamp.svg';


const BusinessPayroll = () => {

    const isMobile = useMediaQuery('(max-width: 500px)');

    const payrollFeatures = [
		{
			img: payrollProcessImg1,
			title: "Centralized Payroll Management",
			content: "Simplify compliance and benefits management. Automate pensions, taxes, payouts, and employee benefits—all from one intuitive dashboard"
		},
		{
			img: payrollProcessImg2,
			title: "Insights And Analytics",
			content: "Track payroll expenditures and forecast expenses to inform strategic workforce management decisions."
		},
		{
			img: payrollProcessImg3,
			title: "Pay Groups",
			content: "Create groups for simple batched application of salaries, bonuses, benefits, and more."
		},
		{
			img: payrollProcessImg4,
			title: "Alerts And Reminders",
			content: "Get customisable notifications on payroll events and timelines to ensure you never miss a deadline."
		},

	];

    const payrollBenefits = [
        {
            img: payrollBenefitsImg1,
            title: "Fast And Precise Automation",
            content: "Save time with automated payroll processes that streamline payroll calculations and reduce manual data entry errors."
        },
        {
            img: payrollBenefitsImg2,
            title: "Transparent Pricing",
            content: "Access transparent and affordable pricing with no hidden fees for payroll solutions that fit your budget."
        },
        {
            img: payrollBenefitsImg3,
            title: "Secure Data Handling",
            content: "Rest assured of the utmost data privacy of employee data with our NDPR compliant data handling processes."
        },
        {
            img: payrollBenefitsImg4,
            title: "Rich Analytics For Decisions",
            content: "Leverage rich, detailed reports for better insights and decision making."
        },
    ]

  return (
    <div>
        <DynamicHeadTag
            headerText="Streamline Your Payroll Process | Efficient & Reliable"
            headerDescription="Automate salary computation, tax deductions, and remittances. Enjoy flexible pay processing and comprehensive reporting for accurate, timely employee payments."
        />

        <section 
            className="page-header-gradient-halfs lg:pb-20 mb-8"
            >
            <img src={bgLineVector} alt="bg line vector" className="bg-line-vector-header" />

            <div className="flex justify-center">
                <div className="flex flex-col max-w-[650px]">
                    <div className="hero-main-text text-center leading-tight powering-business-title" 
                    >
                        People-Focused Payroll For All Business Sizes
                    </div>
                    <div className="text-center mt-5 access-high-yields hero-sub-text">
                        Handle salaries, benefits, deductions, and more with ease.
                    </div>
                </div>
            </div>                

            <div className="flex flex-col items-center">
                <div className='pt-6'>
                    <div className="relative w-60 md:w-[320px] md:h-[52px] cursor-pointer"> 
                        <img src={greenButton} alt="" className=" w-full h-full object-cover" />
                        <div className="absolute left-16 md:left-24 top-1/4 bottom-0 text-white text-sm md:text-base">                  
                            <Link
                                to={{
                                    pathname:
                                    "https://business.earnipay.com/onboarding/get-started",
                                }}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Get Started
                            </Link>  
                        </div>
                    </div>
                </div>
                <div className='flex justify-center pr-5 cursor-pointer'>            
                    <a href="mailto: support@earnipay.com" className="text-ep-primary font-medium underline mt-5 text-[12px] text-underline md:text-base">Contact Sales</a>
                </div>
            </div>
        
            <div className="flex justify-center pt-10 xl:w-[80%] mx-auto">
                <img src={businnessPayrollMainImg} alt="" className="payroll-main-img" />
            </div>
        </section>

        <div className="flex flex-col justify-center mt-20 bg-center bg-contain w-full h-auto pt-5" style={{
          backgroundImage: `url(${isMobile ? greenDiamondBgMobile : greenDiamondBg})`,
          backgroundRepeat: 'no-repeat',
          }}>
          <div className='flex flex-col items-center'>
            <div className="md:text-3xl lg:text-[56px] leading-none text-center ">Built For Every Type Of Business</div>
            <div className="text-gray-600 money-that-works-sub-text md:text-2xl text-center max-w-[600px] pt-6">
            Manage and settle your growing team easily and on time.
            </div>
          </div>
        </div>

        <section className="flex justify-center py-20 p-3">
            <div className="container border border-[#D0D5DD] rounded-3xl p-3 md:p-12 grid grid-cols-1 lg:grid-cols-2 gap-8 max-w-[1200px]">
                {payrollFeatures.map((feature, index) => (
                    <div key={index} className="p-5 rounded-3xl bg-[#F6F8FA] max-w-[550px] mx-auto border border-[#D0D5DD]">
                        <img src={feature.img} alt=""/>                       
                        <div className="px-2 space-y-3">
                            <div className="font-bold text-base lg:text-2xl">{feature.title}</div>
                            <div className="text-xs lg:text-base text-[#686B70]">{feature.content}</div>
                        </div>
                    </div>
                ))}
            </div>
        </section>
        
        <div className="" style={{backgroundImage: `url(${diamondNetBg})`, backgroundPosition: 'top', backgroundRepeat: 'no-repeat', backgroundSize: '100% 500px'}}>
                <div className="flex flex-col justify-center mt-20 bg-center bg-contain w-full h-auto pt-20 pb-6" style={{
                backgroundImage: `url(${isMobile ? greenDiamondBgMobile : greenDiamondBg})`,
                backgroundRepeat: 'no-repeat',
                }}>
                <div className='flex flex-col items-center'>
                    <div className="md:text-3xl lg:text-[56px] leading-none text-center ">What We Offer</div>
                    <div className="text-gray-600 money-that-works-sub-text md:text-2xl text-center max-w-[600px] pt-6">
                        Choosing our Payroll feature means getting the best out of your benefits management processes.
                    </div>
                </div>
                </div>

                <div className="container grid grid-cols-1 md:grid-cols-2 gap-4 gap-y-24 p-2 md:p-20 justify-center mx-auto">
                    {payrollBenefits.map((item, index) => (
                        <div key={index} className="bg-white border-2 border-gray-200/50 p-6 rounded-3xl max-w-[444px] mx-auto">
                            <img src={item.img} alt="" />
                            <div>
                                <div className="font-bold pt-2 text-base lg:text-2xl ">{item.title}</div>
                                <div className="text-gray-500 text-sm lg:text-base pt-2">{item.content}</div>
                            </div>
                        </div>
                    ))
                    }
                </div>        
        </div>

        <div className="flex flex-col justify-center my-20 bg-center bg-contain w-full h-auto" style={{
          backgroundImage: `url(${isMobile ? greenDiamondBgMobile : greenDiamondBg})`,
          backgroundRepeat: 'no-repeat',
          }}>
          <div className='flex flex-col items-center'>
            <div className="md:text-3xl lg:text-[56px] leading-none text-center ">Pricing</div>
            <div className="text-gray-600 money-that-works-sub-text md:text-2xl text-center max-w-[600px] pt-6">
            Only pay for what you use.
            </div>
          </div>
        </div>

        <div className="p-2">
            <div className="container border border-[#D0D5DD] flex flex-col lg:flex-row rounded-3xl bg-[#F6F8FA] md:p-10 h-full gap-8 py-5">
                <div className="flex-1 order-1 flex items-center justify-center">
                    <img className="h-full w-full object-cover rounded-3xl" src={payrollSubGreenDiamond} alt="" />
                </div>
                <div className="flex-1 order-1 md:order-1 p-5 border shadow-md bg-white rounded-3xl flex flex-col space-y-3 2xl:space-y-8 xl:justify-around">
                    <div className="space-y-2">
                        <div>
                            <img src={payrollGreenHand} alt="" />
                        </div>
                        <div className="payroll-wrapper space-y-3">
                            <div className="font-medium md:text-2xl 2xl:text-[1.8rem]">Payroll Subscription</div>
                            <div className="text-gray-600 whitespace-normal pr-2 text-xs md:text-xl 2xl:text-[1.4rem]">We offer market leading rates for Payroll. Our pricing is fully configurable based on what you need, and transparent with zero hidden charges.</div>
                        </div>
                    </div>
                    <div className="pt-3">
                        <div className="text-[#475467] font-medium md:text-base 2xl:text-[1.3rem] absolute">Platform Fee</div>
                        <div className="pt-5 2xl:pt-8">
                            <span className="text-[32px] md:text-[54px] font-bold">&#8358;0 
                                <span className="text-[#475467] text-xs md:text-xl 2xl:text-[1.55rem] font-normal pl-1">/monthly</span>
                            </span>
                        </div>
                    </div>
                    <div>
                        <div className="">
                            <div className="space-y-2 xl:space-y-5">
                                <div className="text-gray-600 font-medium text-xs md:text-base 2xl:text-[1.3rem]">What's Included</div>
                                <div className="space-y-4 text-[#344054]">
                                    <div className="flex items-center space-x-3">
                                        <img src={payrollGreenCircle} alt="" />
                                        <div className="text-gray-700 text-[10px] md:text-base 2xl:text-[1.3rem]">Net Salary - ₦50 per employee </div>
                                    </div>
                                    <div className="flex items-center space-x-3">
                                        <img src={payrollGreenCircle} alt="" />
                                        <div className="text-gray-700 text-[10px] md:text-base 2xl:text-[1.3rem]">PAYE - ₦100 per employee </div>
                                    </div>
                                    <div className="flex items-center space-x-3">
                                        <img src={payrollGreenCircle} alt="" />
                                        <div className="text-gray-700 text-[10px] md:text-base 2xl:text-[1.3rem]">Pensions - ₦100 per employee </div>
                                    </div>
                                </div>
                            
                            </div>
                        </div>
                        <div className="mt-6 2xl:mt-8 cursor-pointer">
                            <Link
                                to={{
                                    pathname:
                                    "https://business.earnipay.com/onboarding/get-started",
                                }}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <button className='get-started-button text-sm md:text-base px-6 btn rounded-3xl'>Get Started <img src={shortWhiteArrow} className='pl-5' alt="" /></button>
                            </Link>  
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="mt-28">
            <CtaSection />
        </div>

    </div>
  )
}

export default BusinessPayroll