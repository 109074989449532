import React from "react";
import highYield1 from "assets/images/high-yield-1.svg";
import highYield2 from "assets/images/high-yield-2.svg";
import highYieldBg from "assets/images/high-yield-bg.svg";
import streamLinedPayment1 from "assets/images/streamlined-payment-1.svg";
import streamLinedPayment2 from "assets/images/streamlined-payment-2.svg";
import streamLinedPaymentNaira from "assets/images/streamlined-payment-naira.svg";
import streamLinedPaymentMobile from "assets/images/streamlined-payment-mobile.png";
import tailoredCreditSolution from "assets/images/tailored-credit-solution.svg";
import unifiedDashboard from "assets/images/unified-dashboard.svg";
import { ReasonToChooseEarnipay } from "./reason-to-choose-earnipay";
import { useMediaQuery } from "hooks/useMediaQuery";
import tailorCreditSolutionMobile from "assets/images/tailor-credit-mobile.png";

export const WhyChooseEarnipay = () => {
	const isMobile = useMediaQuery("(max-width: 768px)");

	return (
		<div className="business-page-section">
			<div className="why-choose-earnipay-wrapper">
				<h3 className="why-choose-earnipay-title">
					Why Choose Earnipay for your Business?
				</h3>
				<div className="reasons-to-choose-earnipay-wrapper">
					<div className="reason-to-choose-earnipay-row">
						<ReasonToChooseEarnipay
							title="Tailored Credit Solutions"
							text="Flexible business loans and overdraft facilities."
							imgSrc={
								isMobile
									? tailorCreditSolutionMobile
									: tailoredCreditSolution
							}
							wrapperClassName="tailored-credit-solution"
							imgWrapperClassName="tailor-credit-img-wrapper"
						/>
						<ReasonToChooseEarnipay
							title="High-Yield Business Accounts:"
							text="Earn interest on your business balances."
							wrapperClassName="high-yield-acct"
							imgElements={
								<div className="high-yield-img-wrapper">
									<img
										src={highYield1}
										alt=""
									/>
									<img
										src={highYield2}
										alt=""
									/>
									<img
										src={highYieldBg}
										alt=""
									/>
								</div>
							}
						/>
					</div>
					<div className="reason-to-choose-earnipay-row">
						<ReasonToChooseEarnipay
							title="Unified Dashboard"
							text="One platform for business accounts, payments, and credit."
							imgSrc={unifiedDashboard}
							wrapperClassName="unified-dashboard"
							imgWrapperClassName="unified-dashboard-img-wrapper"
						/>

						<ReasonToChooseEarnipay
							title="Streamlined Payments"
							text="Make single or bulk payments with custom approval workflows."
							wrapperClassName="stream-lined-payments"
							imgSrc={streamLinedPaymentMobile}
							imgWrapperClassName="stream-lined-payments-img-wrapper-mobile"
							imgElements={
								!isMobile && (
									<div className="sream-lined-payments-img-wrapper">
										<img
											src={streamLinedPaymentNaira}
											alt=""
										/>
										<img
											src={streamLinedPayment1}
											alt=""
										/>
										<img
											src={streamLinedPayment2}
											alt=""
										/>
									</div>
								)
							}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
