import { useState } from 'react';
import greenButton from '../../assets/images/icons/green-button-revamp.svg';
import businessCreditMainImg from 'assets/images/bgs/business-credit-main-img.svg';
import greenDiamondBg from "assets/images/bgs/green-diamond-bg.svg";
import businessTrendUpImg from "assets/images/icons/business-trend-up-img.svg";
import businessDivisionImg from "assets/images/icons/business-division-img.svg";
import businessDividerImg from "assets/images/icons/business-divider-img.svg";
import orangeDiamondBg from "assets/images/bgs/orange-diamond-bg.svg";
import businessCreditCard1 from "assets/images/features/business-credit-card-1.svg";
import businessCreditCard2 from "assets/images/features/business-credit-card-2.svg";
import businessCreditCard3 from "assets/images/features/business-credit-card-3.svg";
import CtaSection from "components/common/cta-green";
import FaqList from 'components/common/new-faq';
import greenDiamondBgMobile from "assets/images/bgs/green-diamond-bg-mobile.svg";
import { useMediaQuery } from 'hooks/useMediaQuery';
import mobileOrangeDiamondBg from "assets/images/bgs/orange-diamond-mobile.svg";
import diamondNetBg from "assets/images/bgs/diamond-net-bg.svg";
import moneyThatYieldsGreenBg from "assets/images/bgs/money-that-yields-green-bg.svg";
import moneyThatYieldsVector from "assets/images/bgs/money-that-yields-vector.svg";
import greenRightArrow from "assets/images/icons/green-right-arrow.svg";
import DynamicHeadTag from 'components/misc/DynamicHeadTag';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


const BusinessCredit = () => {

    const isMobile = useMediaQuery('(max-width: 500px)');

    const creditCards = [
        {
            img: businessTrendUpImg,
            title: "Instant Cash Flow Boost",
            content: "With quick approvals, your business can access the financing it needs to keep thriving."
        },
        {
            img: businessDivisionImg,
            title: "Business Friendly Rates",
            content: "Our competitive rates mean you can manage your costs efficiently."
        },
        {
            img: businessDividerImg,
            title: "Customized Solutions",
            content: "We know every business is unique. Get credit options tailored specifically to your needs."
        },
    ]

    const businessLoanSteps = [
        {
            text: "Onboard and complete the Compliance steps.",
            img: businessCreditCard1,
        },
        {
            text: "Navigate to the Credit menu and submit your bank statement.",
            img: businessCreditCard2,
        },
        {
            text: "Get a response in under 48 hours with a dedicated Relationship Manager.",
            img: businessCreditCard3,
        },
    ]

    const [businessCreditFaqs, setBusinessCreditFaqs] = useState([
        {
            title: "What Documents Do I Need To Provide To Get A Loan?",
            content: "This depends on what type of financing you need, however we typically request bank statements and management accounts. Any other requirements will be communicated to you by your Relationship Manager.",
            isOpen: false,
        },
        {
            title: "How Long Does It Take To Get A Loan?",
            content: "We work with a 72 hour turnaround time because we first want to understand your business to enable us structure the best terms for you. Subsequent requests take less time.",
            isOpen: false,
        },
        {
            title: "What Happens If My Loan Application Is Denied?",
            content: "We may reach out to you to communicate our reasons and work with you to resolve them so you can try again after a while.",
            isOpen: false,
        },
    ]);

    const toggleFaq = (index) => {
        setBusinessCreditFaqs(prevFaqs => prevFaqs.map((faq, i) => 
            i === index ? { ...faq, isOpen: !faq.isOpen } : faq
        ));
    }

  return (
    <div>
        <DynamicHeadTag
            headerText="Earnipay Business Credit | Flexible Loans for Growth and Expansion"
            headerDescription="Access flexible short and long-term loans with Earnipay Business Credit. Benefit from market-leading rates and customizable repayment plans to fuel your business growth and strategic investments."
        />

        <div style={{backgroundImage: `url(${diamondNetBg})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
            <div className="flex flex-col page-header-gradient-half items-center text-center">
                <div className="hero-main-text powering-business-title">
                    Grow At The Speed Of Your <br /> Dreams
                </div>
                <div className="hero-sub-text mt-6 access-high-yields">
                    Access credit that unlocks the growth of your business.
                </div>
                <div className='pt-10'>
                    <div className="relative w-[320px] h-[52px] cursor-pointer"> 
                        <img src={greenButton} alt="" className="p-1 md:p-0 md:w-full h-full object-cover mx-auto" />
                        <div className="absolute top-1/2 left-[48%] transform -translate-x-1/2 -translate-y-1/2 text-white pr-6 text-base md:text-lg">
                            <Link
                                to={{
                                    pathname:
                                        "https://business.earnipay.com/credit",
                                }}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Apply for a Loan
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='flex justify-center pr-5 cursor-pointer'>
                    <div className='text-ep-primary underline my-5 text-[12px] text-underline md:text-base font-medium'>
                        <Link
                        to={{ pathname: "mailto: business@earnipay.com" }}
                        onClick={(e) => {
                            window.location = e.target.getAttribute("href");
                            e.preventDefault();
                        }}
                        >
                        Contact Sales
                        </Link>
                    </div>
                </div>
            </div>
            <div className='flex justify-center mt-5'>
                <div className='relative flex justify-center'>
                    <img src={businessCreditMainImg} alt="" loading='lazy'/>
                    <div className='absolute bottom-0 w-[87%] h-6 blur-effect'></div>
                </div>
            </div>
        </div>


        <div className="flex flex-col justify-center my-20 bg-center bg-contain w-full h-auto" style={{
            backgroundImage: `url(${isMobile ? greenDiamondBgMobile : greenDiamondBg})`,
            backgroundRepeat: 'no-repeat',
        }}>
            <div className='flex flex-col items-center'>
                <div className="md:text-3xl lg:text-[56px] leading-none text-center ">We Back Growing Businesses</div>
                <div className="text-[#6C6E72]  text-sm money-that-works-sub-text md:text-2xl text-center max-w-[600px] pt-5">
                We make financing available to businesses committed to growth, to unlock better cashflow and financial outcomes.
                </div>
            </div>
        </div>

        <div className='relative container flex flex-col md:flex-row'>
            <img src={diamondNetBg} className='absolute w-[95%] container max-w-full' alt="" style={{zIndex: '-10'}}/>
            {creditCards.map((card, index) => (
                <div key={index} className='p-3 lg:p-5 flex-1'>
                    <div className='bg-[#00535C] text-white rounded-xl p-5 h-full max-h-[370px] flex flex-col justify-between'>
                        <div className='space-y-3 pt-3'>
                            <div className='text-xl md:text-2xl font-medium'>{card.title}</div>
                            <div className='text-xs md:text-lg pt-2 text-[#99BABE]'>{card.content}</div>
                        </div>                        
                        <div className='flex justify-end max-h-[170px]'>
                            <img src={card.img} className='w-32 md:w-28 lg:w-[208px] h-auto max-w-full' alt="" loading='lazy'/>
                        </div>
                    </div>
                </div>
            ))}
        </div>

        <div className="flex flex-col justify-center my-20 bg-center bg-contain w-full h-auto" style={{
            backgroundImage: `url(${isMobile ? mobileOrangeDiamondBg : orangeDiamondBg})`,
            backgroundRepeat: 'no-repeat',
        }}>
            <div className='flex flex-col items-center'>
                <div className="md:text-3xl lg:text-[56px] leading-none text-center">Getting A Loan Takes A Few Steps</div>
                <div className="text-[#6C6E72] money-that-works-sub-text md:text-2xl text-center max-w-[600px] pt-5">
                Access the financing you need to take that next step
                </div>
            </div>
        </div>


        <div className='flex flex-col md:flex-row justify-center items-center mx-auto my-5 md:space-x-10 max-w-[1200px] p-2'>
            {businessLoanSteps.map((step, index) => (
                <div key={index} className='w-full md:w-1/3 mb-10 md:mb-0'>
                    <div className='relative h-[260px] flex flex-col items-center'>
                        <img src={step.img} className='h-full w-auto object-contain' alt="" loading='lazy'/>
                        <div className=' text-center h-[171px] w-full absolute top-2/3 flex items-center justify-center border rounded-xl bg-white font-medium text-lg md:text-xl lg:text-2xl p-4'>
                            {step.text}
                        </div>
                    </div>
                </div>
            ))}
        </div>

        <div className='p-3'>
            <div className="relative flex items-center justify-center max-w-[1200px] mx-auto rounded-xl mt-32 h-[460px] overflow-auto" style={{backgroundImage: `url(${moneyThatYieldsGreenBg})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
                <img src={moneyThatYieldsVector} className="absolute bottom-0 left-0" alt="" />
                <div className="p-8 rounded-lg text-center text-white max-w-[750px] mx-auto">
                    <div className="text-3xl md:text-[56px] mb-4 text-white leading-tight">
                        It’s Your Business. Get The
                        <span className="relative inline-block px-2">
                            <span className="absolute inset-0 bg-[#4E6A47] rounded-full -skew-y-3 transform rotate-6" />
                            <span>
                                <span className="absolute inset-0 bg-[#4E6A47] rounded-full -skew-y-12 transform -rotate-5 scale-105" />
                                <span className="relative z-10">Money</span>
                            </span>
                        </span>
                        You Need To Run It.
                    </div>
                    <div className="mb-6 opacity-50 text-lg md:text-xl">Apply now to secure the credit your business needs to grow.</div>
                    <button className="relative px-4 py-2 md:px-6 md:py-3 text-[#004b59] bg-white rounded-full hover:bg-gray-200">
                        Apply For A Loan <img src={greenRightArrow} className='inline-block' alt="" />
                    </button>
                </div>
            </div>
        </div>

        <FaqList faqs={businessCreditFaqs} toggleFaq={toggleFaq} titles="Have Questions?" content= "Some common questions are answered here"/>


       <CtaSection />

    </div>
  )
}

export default BusinessCredit