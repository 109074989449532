import React from "react";

export const ReasonToChooseEarnipay = (props) => {
	const {
		title,
		text,
		imgSrc,
		wrapperClassName,
		imgWrapperClassName,
		imgElements,
	} = props;
	return (
		<div className={`reason-to-choose-earnipay ${wrapperClassName}`}>
			<div className="reason-to-choose-text-wrapper">
				<h5 className="reason-to-choose-earnipay-title">{title}</h5>
				<p className="reason-to-choose-earnipay-text">{text}</p>
			</div>
			<div
				className={`reason-to-choose-earnipay-img-wrapper ${imgWrapperClassName}`}
			>
				{imgElements ? (
					imgElements
				) : (
					<img
						src={imgSrc}
						alt=""
					/>
				)}
			</div>
		</div>
	);
};
