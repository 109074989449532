import { useState } from 'react';
import businessAccountHeroImg from '../../assets/images/bgs/business-account-hero-img.svg';
import greenButton from '../../assets/images/icons/green-button-revamp.svg';
import orangeDiamondBg from "assets/images/bgs/orange-diamond-bg.svg";
import mobileOrangeDiamondBg from "assets/images/bgs/orange-diamond-mobile.svg";
import greenRightArrow from "assets/images/icons/green-right-arrow.svg";
import businessMultipleAccountsImg from "assets/images/bgs/business-multiple-accounts-img.svg";
import businessMoneyFlowGraph from "assets/images/bgs/business-money-flow-graph.svg";
import businessMoveMoneyInOut from "assets/images/bgs/business-move-money-in-out.svg";
import businessTransactionsImg from "assets/images/bgs/business-transactions-img.svg";
import CtaSection from "components/common/cta-green";
import FaqList from 'components/common/new-faq';
import { useMediaQuery } from 'hooks/useMediaQuery';
import antennaImg from "assets/images/icons/personal-savings-network-icon.svg";
import bgLineVector from "assets/images/bg-line-header.svg";
import businessAccountBoxBg from "assets/images/bgs/business-account-boxes.svg";
import businessAccountInvertedBar1 from "assets/images/bgs/business-account-inverted-bar-up.svg";
import businessAccountInvertedBar2 from "assets/images/bgs/business-account-inverted-bar-down.svg";
import moneyThatYieldsGreenBg from "assets/images/bgs/money-that-yields-green-bg.svg";
import yieldCoinPlant from "assets/images/bgs/yield-coin-plant-img.svg";
import totalYieldEarnedImg from "assets/images/bgs/total-yield-earned-img.svg";
import DynamicHeadTag from 'components/misc/DynamicHeadTag';
import { Link } from "react-router-dom";


const BusinessAccount = () => {

  const isMobile = useMediaQuery('(max-width: 500px)');

  const [accountFaqs, setAccountFaqs] = useState([
    {
        title: "What Is A Sub-account?",
        content: "A sub-account is an additional account created for a specific purpose.",
        isOpen: false,
    },
    {
        title: "What Is The Difference Between An Expense And A Collection Account?",
        content: "An expense account allows you to both receive and spend money, while a collection account is used primarily for receiving inflows.",
        isOpen: false,
    },
    {
        title: "Is There Any Requirement For Yield?",
        content: "Yes, the only requirement is for you to opt-in, and have cash in your primary or expense accounts, and you'll earn daily interest.",
        isOpen: false,
    },
    {
        title: "Can I Spend From A Collection Account?",
        content: "No, collection accounts require you to move money into your primary or expense accounts before you can spend from them.",
        isOpen: false,
    },
]);

const toggleFaq = (index) => {
  setAccountFaqs(prevFaqs => prevFaqs.map((faq, i) => 
      i === index ? { ...faq, isOpen: !faq.isOpen } : faq
  ));
}

  return (
    <div>
        <DynamicHeadTag
            headerText="Earnipay High-Yield Business Account | Maximize Your Business Funds"
            headerDescription="Maximize returns with Earnipay’s High-Yield Business Account. Earn daily interest on idle cash, manage multiple sub-accounts, and enjoy top-notch security with zero platform and commitment fees."
        />

        <img src={bgLineVector} alt="bg line vector" className="bg-line-vector-header" />
        
        <div className="flex flex-col items-center text-center page-header-gradient-half">
          <div className="hero-main-text max-w-[850px] powering-business-title">
            High-Yield Accounts For Growth-Focused Businesses
          </div>
          <div className="hero-sub-text mt-2 max-w-[850px] access-high-yields pt-3">
            Maximise your cash flow with features that let you spend, receive payments, and earn returns on your business funds.
          </div>
          
          <div className='pt-10 flex justify-center'>
            <div className="relative w-60 md:w-[320px] md:h-[52px] cursor-pointer"> 
                <img src={greenButton} alt="Open My Account" className="w-full h-full object-cover" />
                <div className="absolute left-[43%] transform -translate-x-1/2 top-1/4 bottom-0 text-white text-sm md:text-base">
                <Link
                    to={{
                        pathname:
                            "https://business.earnipay.com/onboarding/get-started",
                    }}
                    target="_blank"
                    rel="noreferrer"
                >
                    Open My Account
                </Link>
                    
                </div>
            </div>
        </div>
        <div className='flex justify-center pr-5 cursor-pointer'>            
            <a href="mailto: support@earnipay.com" className="text-ep-primary font-medium underline mt-5 text-[12px] text-underline md:text-base">Contact Sales</a>
        </div>
      </div>

      <div className='flex justify-center mt-5'>
          <img src={businessAccountHeroImg} alt="" loading="lazy"/>
      </div>

      <div className="flex flex-col justify-center my-20 bg-center bg-contain w-full h-auto" style={{
				backgroundImage: `url(${isMobile ? mobileOrangeDiamondBg : orangeDiamondBg})`,
				backgroundRepeat: 'no-repeat',
			}}>
				<div className='flex flex-col items-center '>
					<div className="md:text-3xl lg:text-[56px] leading-none text-center ">Accounts That Work For You</div>
					<div className="money-that-works-sub-text text-gray-600 md:text-2xl text-center max-w-[600px] pt-7">
					Zero-fee accounts that yield interest daily with powerful automation tools.
					</div>
				</div>
			</div>

      <div className='p-4'>
        <div className="relative mx-auto max-w-[1000px] flex flex-col md:flex-row justify-between items-center bg-[#0D545B] rounded-2xl md:space-x-5 p-2 h-full md:h-[395px] py-10 md:py-4" style={{backgroundImage: `url(${moneyThatYieldsGreenBg})`}}>
          <div className='p-3 z-10 space-y-5'>
            <div className='text-xl md:text-2xl text-white'>Money That Yields While You Work</div>
            <div className='text-[#ffffffae] text-sm md:text-xl max-w-[500px]'>Earn returns on your cash balances with no commitments, fees, or minimum holding period.</div>
            <div className='btn bg-white text-ep-primary p-2 rounded-3xl mt-4'> Learn More About Yield <img className='pl-2' src={greenRightArrow} alt="" /></div>
          </div>
          <img src={antennaImg} className='absolute h-56 md:h-72 bottom-0 left-0' alt="" loading="lazy"/>
          <div className='pb-5 z-10 relative p-3 md:p-0 pr-5 mix-blend-luminosity'>
            <img src={totalYieldEarnedImg} className='' alt="" loading="lazy"/>
          </div>
            <img src={yieldCoinPlant} className='absolute bottom-20 md:bottom-1/3 right-0 h-28 md:h-40 lg:h-48 z-10' alt="" loading="lazy"/>
        </div>
      </div>

      <div className='p-4'>
        <div style={{ background: 'linear-gradient(to left, #E9FDFF, transparent)' }} className='relative p-3 md:p-8 flex flex-col md:flex-row max-w-[1000px] mx-auto items-center mt-10 border rounded-xl'>
          <img src={businessAccountBoxBg} className='absolute bottom-0 z-[-10]' alt="" />
          <div className='py-5 space-y-3'>
            <div className='text-xl md:text-2xl font-bold'>Set Up Multiple Accounts</div>
            <div className='text-gray-600 text-sm md:text-base'>Organize your finances by creating sub-accounts for specific purposes.</div>
          </div>
          <div className='py-10 md:py-0'>
              <img src={businessMultipleAccountsImg} alt="" loading="lazy"/>
          </div>
        </div>
      </div>

      <div className='p-4'>
        <div className="flex flex-col md:flex-row max-w-[1000px] mt-10 justify-items-center mx-auto space-y-10 md:space-y-0 md:space-x-10" >
          <div className='relative flex flex-col justify-between flex-1 rounded-xl border' style={{ background: 'linear-gradient(to left, #E9FDFF, transparent)' }}>
            <img src={businessAccountBoxBg} className='absolute top-0 w-full' alt="" />
            <div className='p-10 space-y-3'>
              <div className="text-xl md:text-2xl font-bold">Track Money Flow</div>
              <div className="text-gray-600 text-sm md:text-base">Immersive charts help you track your inflows and monitor your business performance.</div>
            </div>
            <div>
              <img className='py-10 md:py-0' src={businessMoneyFlowGraph} alt="" loading="lazy"/>
            </div>
          </div>
          <div className='relative flex-1 rounded-xl border pr-2' style={{ background: 'linear-gradient(to left, #E9FDFF, transparent)' }}>
            <img src={businessAccountBoxBg} className='absolute top-0 w-full' alt="" />
            <div className='space-y-20 absolute bottom-0 track-money-vector w-full z-[-10]'>
              <img src={businessAccountInvertedBar1} alt="" />
              <img src={businessAccountInvertedBar2} alt="" />
            </div>
            <div className='p-10 space-y-3'>
              <div className="text-xl md:text-2xl font-bold">Move Money In And Out</div>
              <div className="text-gray-600 text-sm md:text-base">Transfer funds securely from your primary or expense accounts for better organisation and reconciliation.</div>
            </div>
            <div className='relative'>
              <img src={businessMoveMoneyInOut} className='relative' alt="" loading="lazy"/>
            </div>
          </div>
        </div>
      </div>

      <div className='p-4 '>
        <div style={{ background: 'linear-gradient(to left, #E9FDFF, transparent)' }} className='flex flex-col md:flex-row max-w-[1000px] mx-auto items-center mt-10 border rounded-xl relative'>
          <img src={businessAccountBoxBg} className='absolute bottom-0 z-[-10]' alt="" />
          <div className='p-5 space-y-3'>
            <div className='text-xl md:text-2xl font-bold'>All your Transactions, One place</div>
            <div className='text-gray-600 text-sm md:text-base'>View a complete log of all your transactions, with detailed information on each one.</div>
          </div>
          <div className='py-10 md:pt-14'>
              <img src={businessTransactionsImg} alt="" loading="lazy"/>
          </div>
        </div>
      </div>

      <FaqList faqs={accountFaqs} toggleFaq={toggleFaq} titles="Frequently Asked Questions" content= "All you need to get started with accounts"/>

      <div className='py-10'>
        <CtaSection />
      </div>

    </div>
  )
}

export default BusinessAccount