import React from "react";
import Marquee from "react-fast-marquee";
import brandBluechip from "assets/images/bluechip-light.png";
import brandKennediaConsulting from "assets/images/trustedBrands/kennedia-consulting.png";
import brandThrive from "assets/images/trustedBrands/thrive.png";
import brandCuracel from "assets/images/curacel.svg";
import brandMegawatts from "assets/images/trustedBrands/megawatts.svg";
import brandPreciseLightning from "assets/images/trustedBrands/precise-lighting-logo.png";
import brandEhaClinics from "assets/images/trustedBrands/eha.png";
import brandMaxng from "assets/images/max.svg";
import brandNutscakes from "assets/images/trustedBrands/nuts-cakes.png";

const brands = [
	{
		name: "EHA Clinics",
		logo: brandEhaClinics,
		classes: "h-7",
	},
	{
		name: "Maxng",
		logo: brandMaxng,
		classes: "h-8",
	},
	{
		name: "nutscakes",
		logo: brandNutscakes,
		classes: "h-8",
	},
	{
		name: "Thrive Agric",
		logo: brandThrive,
		classes: "h-12",
	},
	{
		name: "Kennedia Consulting",
		logo: brandKennediaConsulting,
		classes: "h-20",
	},
	{
		name: "Megawatts",
		logo: brandMegawatts,
		classes: "h-8",
	},
	{
		name: "Precise Lightning",
		logo: brandPreciseLightning,
		classes: "",
	},
	{
		name: "Curacel",
		logo: brandCuracel,
		classes: "h-7",
	},
	{
		name: "Bluechip",
		logo: brandBluechip,
		classes: "h-25",
	},
];

export const TrustedByBusinesses = () => {
	return (
		<div className="business-page-section">
			<div className="trusted-by-businesses-wrapper">
				<h3 className="trusted-by-businesses-title">
					Trusted by Leading Businesses
				</h3>
				<div className="flex flex-wrap justify-center mx-auto">
					<Marquee
						speed="50"
						pauseOnHover={true}
					>
						{brands.map((brand, index) => (
							<div
								key={index}
								className={
									brand.classes +
									" flex-shrink-0 mx-8 xl:mx-10 flex grayscale hover:grayscale-0"
								}
							>
								<img
									src={brand.logo}
									alt={brand.name}
									className="w-min sm:w-full h-full my-auto object-contain"
								/>
							</div>
						))}
					</Marquee>
				</div>
			</div>
		</div>
	);
};
