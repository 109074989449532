import React, { useState } from "react";
// import heroBackgroundImage from "assets/images/bgs/business-payroll-line-bg.svg";
import businessOdpHeroImg from "assets/images/bgs/business-odp-hero-img.svg";
import orangeDiamondBg from "assets/images/bgs/orange-diamond-bg.svg";
import benefits1 from "assets/images/bgs/business-odp-benefits-1.svg";
import benefits2 from "assets/images/bgs/business-odp-benefits-2.svg";
import benefits3 from "assets/images/bgs/business-odp-benefits-3.svg";
import benefits4 from "assets/images/bgs/business-odp-benefits-4.svg";
import odpCardSteps1 from "assets/images/bgs/odp-card-steps-1.svg";
import odpCardSteps2 from "assets/images/bgs/odp-card-steps-2.svg";
import odpCardSteps3 from "assets/images/bgs/odp-card-steps-3.svg";
import CtaSection from "components/common/cta-green";
// import greenButton from "../../assets/images/icons/green-button-revamp.svg";
import odpButton from "assets/images/icons/business-odp-button-img.svg";
import Testimonials from "components/common/testimony";
import { useMediaQuery } from 'hooks/useMediaQuery';
import mobileOrangeDiamondBg from "assets/images/bgs/orange-diamond-mobile.svg";
import bgLineVector from "assets/images/bg-line-header.svg";
import diamondNetBg from "assets/images/bgs/diamond-net-bg.svg";
import businessOdpGreenBlocks from "assets/images/bgs/business-odp-green-blocks.svg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import greenRightArrow from "assets/images/icons/green-right-arrow.svg";


const OnDemandPay = () => {

    const isMobile = useMediaQuery('(max-width: 500px)');

    const odpBenefits = [
        {
            image: benefits1,
            title: "Zero Cost",
            content: "Unlock growth without the price tag. Empower your workforce with On-demand Pay at Zero cost to you.",
        },
        {
            image: benefits2,
            title: "No Payroll Disruption",
            content: "Make your employees happy without disrupting your cashflow.",
        },
        {
            image: benefits3,
            title: "Work and Pay Cycle Gap Solved",
            content: "Reduce employee reliance on payday loans with access to the money they’ve earned as they earn it.",
        },
        {
            image: benefits4,
            title: "All-Day Support",
            content: "Easily set limits, customize access, and ensure usage that aligns with your business policies.",
        },
    ]

    const odpCardSteps = [
        {
            image: odpCardSteps1,
            title: 'Verify Eligibility Effortlessly',
            content: 'Submit a bank statement for seamless verification of On-Demand Pay eligibility.',
        },
        {
            image: odpCardSteps2,
            title: 'Customize Employee Benefits',
            content: 'Tailor On-Demand Pay settings: set access percentages, approval requirements, and manage withdrawal fees.',
        },
        {
            image: odpCardSteps3,
            title: 'Invite Your Team to Financial Flexibility',
            content: 'Automatically enroll your employees for On-Demand Pay to enhance financial wellness.',
        },
    ]

    const [activeOdpStep, setActiveOdpStep] = useState(0);

  return (
    <div>
        <section 
            className="w-full h-[100%] bg-cover bg-center page-header-gradient-halfs mb-20"
            >
            <img src={bgLineVector} alt="bg line vector" className="bg-line-vector-header" />

            <div className="flex flex-col justify-center items-center ">
                <div className="text-center hero-main-text powering-business-title">
                    Help Your People Win By Giving Them A Choice
                </div>
                <div className="text-gray-600 text-center hero-sub-text mt-5 access-high-yields">
                    Give your employees access to the money they’ve earned as they earn it, at zero cost to you.
                </div>
                <div className='pt-10'>
                    <a href="https://business.earnipay.com/onboarding/get-started">
                        <img src={odpButton} alt="" className="p-2 md:p-0 w-full h-full object-cover" />
                    </a>
                    
                </div>
                <div className='flex justify-center pr-5 cursor-pointer'>
                    <div className='text-[#00535C] text-underline underline my-5 text-[12px] md:text-base font-medium'>
                        <Link
                        to={{ pathname: "mailto: business@earnipay.com" }}
                        onClick={(e) => {
                            window.location = e.target.getAttribute("href");
                            e.preventDefault();
                        }}
                        >
                            Contact Sales
                        </Link>
                    </div>
                </div>
            </div>
        
            <div className="flex justify-center pt-4">
                <img src={businessOdpHeroImg} alt="" style={{width:'90%',}} />
            </div>
        </section>

        <div className="pt-5">
            <div className="relative flex flex-col justify-center mb-10 bg-center bg-contain w-full h-auto mt-5" style={{
                backgroundImage: `url(${isMobile ? mobileOrangeDiamondBg : orangeDiamondBg})`,
                backgroundRepeat: 'no-repeat',
            }}>
                <img src={diamondNetBg} className="absolute top-[-90px] w-full max-w-[1400px] object-cover mx-auto left-0 right-0 z-[-10]" alt="" />
                <div className='flex flex-col items-center'>
                    <div className="md:text-3xl md:leading-relaxed lg:text-[56px] lg:leading-[1.1] text-center max-w-[728px]">
                        Unlock A Happier, Motivated Workforce
                    </div>
                    <div className="money-that-works-sub-text orange-diamond-subtext md:text-2xl text-center max-w-[650px]">
                        On-demand access to salary that's already earned, all month
                    </div>
                </div>
            </div>
        </div>
        
        <div className="container grid grid-cols-1 md:grid-cols-2 gap-4 gap-y-28 p-4 md:p-20 md:pt-0 justify-center mx-auto">
            {odpBenefits.map((benefit, index) => (
                <div key={index} className=" border-2 border-[#E6E6E6] bg-white p-8 rounded-xl max-w-[444px] mx-auto">
                    <img src={benefit.image} alt="" />
                    <div className="pt-3 space-y-2">
                        <div className="font-bold lg:text-2xl">{benefit.title}</div>
                        <div className="text-base text-[#6C6E72]">{benefit.content}</div>
                    </div>
                </div>
            ))
            }
        </div>

        <div className="flex flex-col justify-center my-24 bg-center bg-contain w-full h-auto" style={{
            backgroundImage: `url(${isMobile ? mobileOrangeDiamondBg : orangeDiamondBg})`,
            backgroundRepeat: 'no-repeat',
        }}>
            <div className='flex flex-col items-center'>
                <div className="md:text-3xl lg:text-[56px] leading-none text-center">Get Started With On-Demand Pay</div>
                <div className="orange-diamond-subtext money-that-works-sub-text md:text-2xl text-center max-w-[600px]">
                    Unlock a happier workforce in 3 steps
                </div>
            </div>
        </div>

        <div className="md:p-10">
            <div className="mt-14 sm:mt-20 lg:mt-[72px] overflow-hidden">
                <div className="container relative flex flex-col lg:flex-row bg-[#0D545B] rounded-2xl max-w-[1400px] min-h-[592px] w-full p-6 sm:px-10 pt-10  lg:pt-[105.3px] xl:pb-0" 
                    style={{
                        backgroundImage: `url(${businessOdpGreenBlocks})`, 
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                    }}>
                    <div className="relative w-full xl:w-[500px] h-full gap-8 flex flex-col justify-between">
                        <div className="absolute h-full bg-[#2E7279] w-1 z-[0]" />
                        {[...Array(3)].map((_, index) => (
                            <div key={index} onClick={() => setActiveOdpStep(index)} 
                                className={`
                                    relative w-full h-[75px] px-4 flex items-center cursor-pointer transition-all duration-300
                                    ${activeOdpStep === index 
                                        ? "border-l-4 border-[#B6ECF2] text-white" 
                                        : "border-l-4 border-[#2E7279] text-[#6E989D]"
                                    }
                                `}
                            >
                                <div className="w-full">
                                    <div className="text-sm sm:text-lg xl:text-2xl xl:leading-[28.8px] font-medium text-left">{odpCardSteps[index].title}</div>
                                    <div className={`text-xs sm:text-sm xl:text-base text-left 
                                        ${activeOdpStep === index ? "text-[#9EBBBD]" : "text-[#477D82]"}`}>
                                        {odpCardSteps[index].content}
                                    </div> 
                                </div>
                            </div>
                        ))}
                    </div>
                        <div className="absolute bottom-[97.77px] w-full lg:flex justify-start pt-10 hidden">
                            <button className="px-8 py-3 text-[#0D545B] bg-white rounded-full hover:bg-gray-200 text-sm xl:text-xl transition-colors duration-300 flex items-center">
                                <a href="https://business.earnipay.com/onboarding/get-started">Sign Up</a>  
                                <img src={greenRightArrow} className='hidden sm:inline-block ml-2' alt="Right Arrow" />
                            </button>
                        </div>
                        <div className="my-10 lg:hidden">
                            <button className="px-8 py-3 text-[#0D545B] bg-white rounded-full hover:bg-gray-200 text-sm xl:text-xl transition-colors duration-300 flex items-center">
                                <a href="https://business.earnipay.com/onboarding/get-started">Sign Up</a>  
                                <img src={greenRightArrow} className='hidden sm:inline-block ml-2' alt="Right Arrow" />
                            </button>
                        </div>
                    <div className="w-full xl:w-[650px] flex justify-center items-center -mb-6 sm:-mb-28 xl:-mt-6 xl:ml-auto">
                        <div className="w-full overflow-hidden">
                            <img 
                                src={odpCardSteps[activeOdpStep].image} 
                                alt={odpCardSteps[activeOdpStep].title} 
                                className="w-full h-[300px] sm:h-[500px] md:object-contain rounded-t-3xl transition-transform duration-300 ease-in-out transform hover:scale-95" 
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="flex flex-col justify-center my-20 bg-center bg-contain w-full h-auto -mb-14" style={{
            backgroundImage: `url(${isMobile ? mobileOrangeDiamondBg : orangeDiamondBg})`,
            backgroundRepeat: 'no-repeat',
        }}>
            <div className='flex flex-col items-center'>
                <div className="md:text-3xl lg:text-[56px] leading-none text-center">Empowering Employers</div>
                <div className="money-that-works-sub-text md:text-2xl text-center max-w-[800px] orange-diamond-subtext">
                    Discover how On-Demand Pay is reshaping workplace benefits and employee satisfaction
                </div>
            </div>
        </div>

        <Testimonials />

        <div className="my-24">
            <CtaSection />
        </div>


    </div>
  )
}

export default OnDemandPay