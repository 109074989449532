import React from "react";
import servicesLines from "assets/images/interested-services-lines.svg";
import servicesLinesMobile from "assets/images/services-line-mobile.png";
import greenCloudBg from "assets/images/bgs/green-cloud-bg.svg";
import { Link } from "react-router-dom";
import { useMediaQuery } from "hooks/useMediaQuery";

export const IntrestedInEarnipay = () => {
	const isMobile = useMediaQuery("(max-width: 768px)");

	return (
		<div className="business-page-section">
			<div
				className="interested-in-earnipay-wrapper"
				style={{
					backgroundPosition: "center",
					backgroundImage: `url(${greenCloudBg})`,
					backgroundSize: "cover",
					backgroundRepeat: "no-repeat",
				}}
			>
				<div className="interested-in-earnipay-text-cta">
					<h3 className="interested-in-earnipay-title">
						Interested in Earnipay for your Business?
					</h3>
					<div className="interested-in-earnipay-cta">
						<div className="interested-in-earnipay-btn">
							<Link
								to={{
									pathname:
										"https://business.earnipay.com/onboarding/get-started",
								}}
								target="_blank"
								rel="noreferrer"
							>
								Create A Business Account
							</Link>
						</div>
						<div className="underline text-underline interested-in-earnipay-link">
							<Link
								to={{
									pathname: "mailto: business@earnipay.com",
								}}
								onClick={(e) => {
									window.location =
										e.target.getAttribute("href");
									e.preventDefault();
								}}
							>
								Contact Sales
							</Link>
						</div>
					</div>
				</div>
				<div className="interested-in-earnipay-img-wrapper">
					<img
						src={isMobile ? servicesLinesMobile : servicesLines}
						alt="earnipay services"
					/>
				</div>
			</div>
		</div>
	);
};
