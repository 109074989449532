import React, { useEffect, useState } from "react";
import loanSteps1 from "assets/images/bgs/loan-steps-img-1.svg";
import loanSteps2 from "assets/images/bgs/loan-steps-img-2.svg";
import loanSteps3 from "assets/images/bgs/loan-steps-img-3.svg";
// import directionArrowActive from "assets/images/icons/direction-down-circle-active.svg";
// import directionArrowInactive from "assets/images/icons/direction-down-circle-inactive.svg";
// import Maybe from "components/common/Maybe";
import DynamicHeadTag from "components/misc/DynamicHeadTag";
import googleAnalyticsTrack from "utils/googleAnalyticsTrack";
// import appStoreBlack from "assets/images/icons/app-store-black.svg";
// import playStoreBlack from "assets/images/icons/play-store-black.svg";
import creditHeroImg from "assets/images/bgs/credit-hero-img.svg";
// import arrowWhite from "assets/images/icons/arrow-right-white.svg";
import creditFastTrackImg from "assets/images/bgs/credit-fast-track-dreams.svg";
// import creditLoansImg from "assets/images/bgs/credit-loans-frame-img.svg";
import creditPerksImg1 from "assets/images/bgs/credit-perks-img-1.svg";
import creditPerksImg2 from "assets/images/bgs/credit-perks-img-2.svg";
import creditPerksImg3 from "assets/images/bgs/credit-perks-img-3.svg";
import creditPerksImg4 from "assets/images/bgs/credit-perks-img-4.svg";
// import gettingLoanFewStepsImg from "assets/images/bgs/getting-loan-few-steps.svg";
// import directionRightCircle from "assets/images/icons/direction-right-circle.svg";
import greenDiamondBg from "assets/images/bgs/green-diamond-bg.svg";
import mobileGreenDiamondBg from "assets/images/bgs/mobile-green-diamond-bg.svg";
// import goldenLineMobile from "assets/images/money-that-works-mobile.svg";
import goldenLineBg from "assets/images/bgs/golden-diamond-line-bg.svg";
import { PlayStoreButtons } from "components/common/play-store-buttons";
import { useMediaQuery } from "hooks/useMediaQuery";
import savingsVector from "assets/images/bgs/loans-landing-vector.svg";
import bgLineVector from "assets/images/bg-line-header.svg";
import diamondNetBg from "assets/images/bgs/diamond-net-bg.svg";
import businessOdpGreenBlocks from "assets/images/bgs/business-odp-green-blocks.svg";
import greenRightArrow from "assets/images/icons/green-right-arrow.svg";
import FaqList from 'components/common/new-faq';
import OnePlatformForMoney from "components/common/one-platform-money-banner";


const ProductsBulkPayment = () => {

	const isMobile = useMediaQuery('(max-width: 500px)');

	const creditPerks = [
		{
			img: creditPerksImg1,
			title: "Available On Mobile",
			content: "We assess you completely on mobile with no long forms or complex application processes"
		},
		{
			img: creditPerksImg2,
			title: "Ethically Priced",
			content: "Our rates are benchmarked on the best bank rates. As a policy, we do not offer exorbitant or predatory rates."
		},
		{
			img: creditPerksImg3,
			title: "Transparent Terms",
			content: "Our terms are always clearly stated with no sneaky fine print or hidden fees and charges."
		},
		{
			img: creditPerksImg4,
			title: "Quick Decisions",
			content: "We respond quickly to your request with no ambiguity or ever-changing requirements."
		},

	];

	const [activeLoanStep, setActiveLoanStep] = useState(0);

	const loanSteps = [
		{
			image: loanSteps1,
			title: "Complete Your KYC to Access Loans",
			content:
				"To be eligible for loan requests on the Earnipay app, you will need to complete our KYC process",
		},
		{
			image: loanSteps2,
			title: "Get Your Limit",
			content:
				"Access your loan limit after your bank statement is analysed",
		},
		{
			image: loanSteps3,
			title: "Loan Disbursement",
			content:
				"Choose your preferred loan amount and repayment period, get your loan offer and access your funds.",
		},
	];

	const [creditFaqs, setCreditFaqs] = useState([
		{
			title: "What information is required for my loan application?",
			content: "Your bank statement is required. It will be collected digitally directly from your bank during the loan application process.",
			isOpen: false,
		},
		{
			title: "How long does the loan approval and disbursement process take?",
			content: "You are qualified for a loan under 24 hours, and disbursement is done in a few seconds from the moment you select how much you want.",
			isOpen: false,
		},
		{
			title: "What happens if my loan application is denied?",
			content: "We may reach out to you to gather more information, or ask that you retry after a while as we continue to build your credit profile.",
			isOpen: false,
		},
	]);

	const toggleFaq = (index) => {
		setCreditFaqs(prevFaqs => prevFaqs.map((faq, i) => 
			i === index ? { ...faq, isOpen: !faq.isOpen } : faq
		));
	  }

    useEffect(() => {
        googleAnalyticsTrack();
    }, [])

	return (
		<div>
			<DynamicHeadTag
				headerText="Bulk Airtime, Data and Vendor payments | Earnipay"
				headerDescription="Pay up to 3,000+ Bills in less than 1 minute. Buy airtime, data and much more for your teams and business."
			/>
			<img src={bgLineVector} alt="bg line vector" className="bg-line-vector-header" />
			
			<section className="relative">
				<div className="flex flex-col items-center text-center page-header-gradient-halfs bg-ep-primary-light">
					<div className="text-2xl md:text-5xl font-medium hero-main-text powering-business-title">
						Borrow Better, Achieve More
					</div>
					<div className="text-gray-600 mt-6 text-[12px] hero-sub-text md:text-xl w-full access-high-yields">
						Access affordable loans to bring your dreams to life
					</div>
					<div className="mt-12 first-landing-playstore-btns pt-5 md:pt-0">
						<PlayStoreButtons />
					</div>

					<div className="mt-8 mb-16 flex justify-center ml-14 md:pl-32">
						<img src={creditHeroImg} className="w h-auto md:h-[494px]" alt="" />
					</div>

					<img src={savingsVector} alt="" className="vector-header absolute" />
				</div>
			</section>


			<div className="flex justify-center px-6 md:px-10 lg:px-14 md:pb-[72px]">
				<div className="flex flex-col md:flex-row justify-center md:space-x-10 md:pt-10 max-w-[1200px] space-y-10 md:space-y-0 items-stretch">
					<div className="flex-1 max-w-[560px]  md:space-y-6 md:px-4">
						<div className="text-[28px] md:text-2xl lg:text-5xl max-w-[300px]">Fast-Track <span className="lg:block md:pt-2">Your Dreams</span></div>
						<div className="text-[#6C6E72] mt-2 text-sm md:text-xl lg:text-2xl">Whether for vacation, a car, or a new phone, don't wait for inflation to hit. Get assessed and approved for ethically priced loans in minutes.</div>
						<button className="rounded-full py-3 personal-loan-apply-btn mt-5 px-12 text-base">Apply For A Loan</button>
					</div>
					<div className="flex-1 flex justify-center items-center">
						<img src={creditFastTrackImg} className="w-full mx-auto object-cover z-[-8]" alt="" />
					</div>
				</div>
			</div>

			<div className="relative flex flex-col justify-center my-20 bg-center bg-contain w-full h-auto" style={{
				backgroundImage: `url(${isMobile ? mobileGreenDiamondBg : greenDiamondBg})`,
				backgroundRepeat: 'no-repeat',
			}}>
				<div className='flex flex-col items-center'>
					<div className="md:text-3xl lg:text-[56px] leading-none text-center ">Loans Don't Have To Be Scary</div>
					<div className="orange-diamond-subtext money-that-works-sub-text md:text-2xl text-center max-w-[750px] pt-3">
						Your financial health is our biggest desire, and we have designed our loans to work for you, not against you.
					</div>
				</div>
				
				<img src={diamondNetBg} className="absolute top-28 z-[-10]" alt="" />
			</div>

			<div className="grid grid-cols-1 lg:grid-cols-2 gap-6 md:gap-12 p-5 mt-[-50px] md:mt-0 max-w-[936px] justify-center mx-auto">
				{creditPerks.map((item, index) => (
					<div key={index} className="shadow-md border border-gray-100 p-10 rounded-xl bg-white max-w-[444px] max-h-[250px]">
						<img src={item.img} alt="" />
						<div className="mx-auto">
							<div className="font-bold text-2xl py-3 leading-7">{item.title}</div>
							<div className="text-gray-500 text-sm md:text-base leading-5">{item.content}</div>
						</div>
					</div>
				))}
			</div>
			
			<div className='md:pt-28'>
				<div className="flex flex-col justify-center my-20 bg-center bg-contain w-full h-auto bg-white" style={{
					backgroundImage: `url(${isMobile ? mobileGreenDiamondBg : goldenLineBg})`,
					backgroundRepeat: 'no-repeat',
				}}>
					<div className='flex flex-col items-center space-y-3'>
						<div className="md:text-3xl lg:text-[56px] leading-none text-center text-xl">Getting A Loan Takes A Few Steps</div>
						<div className="orange-diamond-subtext money-that-works-sub-text md:text-2xl text-center max-w-[600px]">
							Access the financing you need to take that next step
						</div>
					</div>
				</div>
			</div>

			<div className="p-3 md:p-10 md:py-0">
				<div className="mt-14 sm:mt-20 lg:mt-[72px] overflow-hidden container">
					<div className=" relative flex flex-col lg:flex-row bg-[#106368] rounded-2xl max-w-[1400px] min-h-[592px] w-full p-6 sm:px-10 pt-10  lg:pt-[105.3px] xl:pb-0" 
						style={{
							backgroundImage: `url(${businessOdpGreenBlocks})`, 
							backgroundPosition: 'center',
							backgroundRepeat: 'no-repeat',
							backgroundSize: 'cover',
						}}>
						<div className="relative w-full xl:w-[500px] h-full gap-8 flex flex-col justify-between">
							<div className="absolute h-full bg-[#2E7279] w-1 z-[0]" />
							{[...Array(3)].map((_, index) => (
								<div key={index} onClick={() => setActiveLoanStep(index)} 
									className={`
										relative w-full h-[75px] px-4 flex items-center cursor-pointer transition-all duration-300
										${activeLoanStep === index 
											? "border-l-4 border-[#B6ECF2] text-white" 
											: "border-l-4 border-[#2E7279] text-[#6E989D]"
										}
									`}
								>
									<div className="w-full">
										<div className="text-sm sm:text-lg xl:text-2xl xl:leading-[28.8px] font-medium text-left">{loanSteps[index].title}</div>
										<div className={`text-xs sm:text-sm xl:text-base text-left 
											${activeLoanStep === index ? "text-[#9EBBBD]" : "text-[#477D82]"}`}>
											{loanSteps[index].content}
										</div> 
									</div>
								</div>
							))}
						</div>
							<div className="absolute bottom-[97.77px] w-full lg:flex justify-start pt-10 hidden">
								<button className="px-8 py-3 text-[#0D545B] bg-white rounded-full hover:bg-gray-200 text-sm xl:text-xl transition-colors duration-300 flex items-center">
									<a href="https://business.earnipay.com/onboarding/get-started">Apply For A Loan</a>  
									<img src={greenRightArrow} className='ml-2' alt="Right Arrow" />
								</button>
							</div>
							<div className="my-10 lg:hidden">
								<button className="px-8 py-3 text-[#0D545B] bg-white rounded-full hover:bg-gray-200 text-sm xl:text-xl transition-colors duration-300 flex items-center">
									<a href="https://business.earnipay.com/onboarding/get-started">Apply For A Loan</a>  
									<img src={greenRightArrow} className='ml-2' alt="Right Arrow" />
								</button>
							</div>
						<div className="w-full xl:w-[650px] flex justify-center items-center -mb-6 sm:-mb-28 xl:-mt-6 xl:ml-auto">
							<div className="w-full overflow-hidden">
								<img 
									src={loanSteps[activeLoanStep].image} 
									alt={loanSteps[activeLoanStep].title} 
									className="w-full h-[300px] sm:h-[500px] md:object-contain rounded-t-3xl transition-transform duration-300 ease-in-out transform hover:scale-95" 
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<FaqList faqs={creditFaqs} toggleFaq={toggleFaq} titles="Have Questions?" content= "Some common questions about loans are answered here."/>

			<div className="mt-[-100px]">
				<OnePlatformForMoney />
			</div>

			{/* <WideBanner
				title="Get Better Business Outcomes with Earnipay"
				description={
					<>
						Let us empower your business with our range of{" "}
						<br className="hidden xl:block" /> finance and people solutions
					</>
				}
				buttonLink={"https://business.earnipay.com/onboarding/get-started"}
				buttonText="Get Started"
				openInNewTab={true} */}
		</div>
	);
};

export default ProductsBulkPayment;
