import React, { useEffect/* , useState */ } from "react";
import DynamicHeadTag from "components/misc/DynamicHeadTag";
import googleAnalyticsTrack from "utils/googleAnalyticsTrack";
import rewardsHeroImg from "assets/images/bgs/rewards-hero.svg";
import howItWorksSub1 from "assets/images/bgs/how-it-works-sub1.svg";
import howItWorksSub2 from "assets/images/bgs/how-it-works-sub2.svg";
import howItWorksSub3 from "assets/images/bgs/how-it-works-sub3.svg";
import OnePlatformForMoney from "components/common/one-platform-money-banner";
import newRewardsIphone from "assets/images/bgs/newRewardsIphone.svg";
import { PlayStoreButtons } from "components/common/play-store-buttons";
import { useMediaQuery } from "hooks/useMediaQuery";
import orangeDiamondBg from "assets/images/bgs/orange-diamond-bg.svg";
import mobileOrangeDiamondBg from "assets/images/bgs/orange-diamond-mobile.svg";
import rewardsPerksGreenBg from "assets/images/bgs/rewards-perks-green-bg.svg";
import rewardsHeroCoinsLeft from "assets/images/bgs/rewards-hero-coins-left.svg";
import rewardsHeroCoinsRight from "assets/images/bgs/rewards-hero-coins-right.svg";
import bgLineVector from "assets/images/bg-line-header.svg";
import heroWavyVector from "assets/images/bgs/hero-wavy-lines-vector.svg";
import diamondNetBg from "assets/images/bgs/diamond-net-bg.svg";
import rewardsCoinStack from "assets/images/bgs/rewards-coin-stack.svg";


const Rewards = () => {

	const isMobile = useMediaQuery('(max-width: 500px)');

	useEffect(() => {
		googleAnalyticsTrack();
	}, [])

	return (
		<div>

			<DynamicHeadTag
				headerText="Unlock Exciting Rewards with Earnipay - Join Now and Enjoy!"
				headerDescription="Join Earnipay and reap the rewards! Earn points on every transaction and redeem them for exciting rewards. From cashback to exclusive offers, there's something for everyone."
			/>

			<div className="2xl:container">
				<img src={bgLineVector} alt="bg line vector" className="bg-line-vector-header" />
			</div>

			<div className="relative flex flex-col items-center text-center page-header-gradient-halfs">
				<div className="hero-main-text powering-business-title z-10" style={{maxWidth: '786px'}}>
					Eat Your Cake and Have It With Rewards
				</div>
				<div className="hero-subtext mt-6 access-high-yields z-10">
					Get rewarded for transactions with discounts, bonuses, and points redeemable at your favourite outlets.
				</div>
				<div className="pt-4 md:pt-10 first-landing-playstore-btns z-10">
					<PlayStoreButtons />
				</div>

				<div className="mt-8 mb-16 flex justify-center">
					<img src={rewardsHeroImg} className="h-auto w-full" alt="" />
				</div>

				<img src={heroWavyVector} className="bg-wavy-line-vector" alt="" />
				
				<div className="container">
					<img src={rewardsHeroCoinsRight} className="absolute right-0 bottom-1/4 rewards-hero-coins" alt="" />
					<img src={rewardsHeroCoinsLeft} className="absolute left-0 bottom-1/2 rewards-hero-coins" alt="" />
				</div>
			</div>

			<div className="flex flex-col justify-center mt-14 lg:mt-44 bg-center bg-contain w-full h-auto mb-5" style={{
				backgroundImage: `url(${isMobile ? mobileOrangeDiamondBg : orangeDiamondBg})`,
				backgroundRepeat: 'no-repeat',
			}}>
				<div className='flex flex-col items-center'>
					<div className="md:text-3xl lg:text-[56px] leading-none text-center ">How To Use On-Demand Pay</div>
					<div className="orange-diamond-subtext money-that-works-sub-text md:text-2xl text-center max-w-[600px]">
						Get access to your money in 3 steps
					</div>
				</div>
			</div>

			<div className="relative p-3 md:p-10">
				<div className="flex justify-center">
					<img src={diamondNetBg} className="absolute top-1/3 z-[-10]" alt="" />
				</div>

				<div className="flex flex-col md:flex-row md:space-x-12 justify-center">
					<div className="h-auto"><img src={howItWorksSub1} className="h-full w-full object-cover rounded-3xl" alt="" /></div>
					<div className="h-auto"><img src={howItWorksSub2} className="h-full w-full object-cover rounded-3xl" alt="" /></div>
					<div className="h-auto"><img src={howItWorksSub3} className="h-full w-full object-cover rounded-3xl" alt="" /></div>
				</div>
			</div>

			<div className="flex justify-center mb-14">
				<div className="btn bg-[#115D66] rounded-3xl mt-14 mb-14 px-5 green-btn-outline text-white hidden md:flex lg:text-base cursor-pointer">Get Started With Earnipay</div>
			</div>

			<div className="md:p-10 p-3">
				<div className="container text-white rounded-[34px] pt-10" style={{backgroundImage: `url(${rewardsPerksGreenBg})`, backgroundPosition: 'center', backgroundSize: 'cover'}}>
					<div className="text-center p-2 md:p-0">
						<div className="font-medium text-2xl md:text-4xl lg:text-5xl">Smart Money Moves That<br /> Earn You Rewards</div>
						<div className="text-sm md:text-xl pt-4">A rewards system designed to put money back into your pocket</div>
					</div>
					<div className="flex flex-col md:flex-row justify-center items-center md:space-x-28">
						<div className="p-1 md:p-7">
							<div className="space-y-10 max-w-[450px] text-center w-full">
								<div className="rewards-green-card p-3 bg-opacity-5 bg-green-500 flex flex-col items-center justify-center">
									<div className="text-xl lg:text-2xl font-medium pb-3">Diverse Redemption Options</div>
									<div className="text-sm lg:text-base">Choose from a variety of rewards—from discounts to exclusive experiences—that match your unique preferences</div>
								</div>
								<div className="rewards-green-card p-3 bg-opacity-5 bg-green-500 flex flex-col items-center justify-center">
									<div className="text-xl lg:text-2xl font-medium pb-3">Earn Points, Unlock Rewards</div>
									<div className="text-sm lg:text-base">Every interaction on the Earnipay App is an opportunity to earn points. Accumulate them to unlock a world of perks and exciting rewards.</div>
								</div>
								<div className="rewards-green-card p-3 bg-opacity-5 bg-green-500 flex flex-col items-center justify-center">
									<div className="text-xl lg:text-2xl font-medium pb-3">Surprises Along The Way</div>
									<div className="text-sm lg:text-base">Enjoy delightful surprises and exclusive offers. We believe in making every interaction with us memorable.</div>
								</div>
							</div>
						</div>
						<div className="pt-10 relative">
							<img src={newRewardsIphone} alt="Rewards iPhone" />
							<img src={rewardsCoinStack} className="absolute right-0 bottom-0" alt="" />
						</div>
					</div>
				</div>

			</div>

			<OnePlatformForMoney />

		</div>
	);
};

export default Rewards