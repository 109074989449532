import React from "react";
import { Link } from "react-router-dom";
import logo from "assets/images/logo-new.svg";
import facebook from "assets/images/social/facebook-black.svg";
import twitter from "assets/images/social/x-black.svg";
import instagram from "assets/images/social/instagram-black.svg";
import linkedin from "assets/images/social/linkedin-black.svg";
import nprAuditMobile from "assets/images/npr-audit-mobile.png";
// import certification from "assets/images/earnipay-certification.png";
import ndpraudit from "assets/images/ndpr-audit.svg";
import earnipayFooterBg from "assets/images/earnipay-footer-bg.svg";
import earnipayFooterBgMobile from "assets/images/earnipay-footer-bg-mobile.svg";
import "assets/css/business.css";
import { useMediaQuery } from "hooks/useMediaQuery";

const NewFooter = () => {
	const isMobile = useMediaQuery("(max-width: 768px)");

	return (
		<section
			className="font-aeonik"
			style={{
				background: "linear-gradient(to bottom, transparent, #E5FCFF",
			}}
		>
			<div className="business-page-section pt-20 pb-28 relative">
				<div className="lg:flex lg:space-x-14 justify-between items-start">
					<div className="earnipay-and-cert-footer lg:w-1/4 lg:flex-shrink-0">
						<div>
							<img
								src={logo}
								alt="Earnipay logo"
								className="footer-logo"
							/>
						</div>
						<div className="w-auto flex flex-col md:w-48 xl:w-auto">
							<div className="footer-socials-wrapper lg:block">
								<div className="flex my-6 space-x-4">
									<Link
										to={{
											pathname:
												"https://www.linkedin.com/company/earnipay",
										}}
										target="_blank"
										className="inline-block footer-social-icon"
									>
										<img
											src={linkedin}
											alt="linkenin"
											className="object-contain"
										/>
									</Link>
									<Link
										to={{
											pathname:
												"https://www.instagram.com/getearnipay/",
										}}
										target="_blank"
										className="inline-block footer-social-icon"
									>
										<img
											src={instagram}
											alt="instagram"
											className="object-contain"
										/>
									</Link>
									<Link
										to={{
											pathname:
												"https://www.facebook.com/Earnipay",
										}}
										target="_blank"
										className="inline-block footer-social-icon"
									>
										<img
											src={facebook}
											alt="facebook"
											className="object-contain"
										/>
									</Link>
									<Link
										to={{
											pathname:
												"https://www.twitter.com/earnipay",
										}}
										target="_blank"
										className="inline-block footer-social-icon"
									>
										<img
											src={twitter}
											alt="twitter"
											className="object-contain"
										/>
									</Link>
								</div>
							</div>

							<img
								src={isMobile ? nprAuditMobile : ndpraudit}
								alt="Earnipay NDPR Certification"
								className="ndpr-icon pt-2"
							/>

							<div className="hidden text-base">
								&copy; <span>{new Date().getFullYear()}</span>{" "}
								Earnipay Inc. All rights reserved.
							</div>
							<div className="hidden text-base">
								Plot 8, Providence Street, Lekki 1, Lagos.
							</div>
						</div>
					</div>

					{/* <div className="lg:w-3/4 mt-10 lg:mt-0 grid grid-cols-2 md:grid-cols-4 gap-x-2 xl:gap-x-16 gap-y-10 md:gap-y-0 text-sm"> */}
					<div
						className="lg:w-3/4 mt-10 lg:mt-0 grid grid-cols-2 md:grid-cols-4 gap-x-2 xl:gap-x-16 gap-y-10 md:gap-y-0 text-sm"
						style={{ marginLeft: 0 }}
					>
						<div className="space-y-4">
							<div className="footer-link-title pb-2">
								Business
							</div>
							<div>
								<Link
									to="/business/credit"
									className="hover:underline footer-link"
								>
									Credit
								</Link>
							</div>
							<div>
								<Link
									to="/business/account"
									className="hover:underline footer-link"
								>
									Accounts
								</Link>
							</div>
							<div>
								<Link
									to="/business/payment"
									className="hover:underline footer-link"
								>
									Payments
								</Link>
							</div>
							<div>
								<Link
									to="/business/payroll"
									className="hover:underline footer-link"
								>
									Payroll
								</Link>
							</div>
							<div>
								<Link
									to="/business/odp"
									target="_blank"
									className="hover:underline footer-link"
								>
									On-Demand Pay
								</Link>
							</div>

							{/* <div>
                                    <Link to="/careers" target="_blank" className="hover:underline footer-link">Careers</Link>
                                </div> */}
							{/* <div>
                                <Link to={{ pathname: "https://blog.earnipay.com/" }} target="_blank" rel="noreferrer" className="hover:underline footer-link">
                                    Blog
                                </Link>
                            </div> */}
						</div>

						<div className="space-y-4">
							<div className="footer-link-title pb-2">
								Personal
							</div>
							<div>
								<Link
									to="/personal/credit"
									className="hover:underline footer-link"
								>
									Loans
								</Link>
							</div>
							<div>
								<Link
									to="/personal/on-demand-pay"
									className="hover:underline footer-link"
								>
									On-Demand Pay
								</Link>
							</div>
							<div>
								<Link
									to="/personal/rewards"
									className="hover:underline footer-link"
								>
									Rewards
								</Link>
							</div>
							<div>
								<Link
									to="/personal/savings"
									className="hover:underline footer-link"
								>
									Savings
								</Link>
							</div>
						</div>

						<div className="space-y-4">
							<div className="footer-link-title pb-2">
								Company
							</div>

							<div>
								<Link
									to="#"
									className="hover:underline footer-link"
								>
									About Us
								</Link>
							</div>
							<div>
								<Link
									to={{
										pathname:
											"https://earnipay.bamboohr.com/jobs",
									}}
									target="_blank"
									className="hover:underline footer-link"
								>
									Careers
								</Link>
							</div>
							<div>
								<Link
									to="/privacy-policy"
									className="hover:underline footer-link"
								>
									Privacy Policy
								</Link>
							</div>
							<div>
								<Link
									to="/terms"
									className="hover:underline footer-link"
								>
									Terms and Conditions
								</Link>
							</div>
						</div>

						<div className="space-y-4">
							<div className="text-gray-500 pb-2">Contact Us</div>
							<div>
								<div className="footer-link">
									For Business Enquiries:
								</div>
								<div>
									<a
										href="mailto: support@earnipay.com"
										className="hover:underline text-ep-primary-500"
									>
										{" "}
										business@earnipay.com{" "}
									</a>
								</div>
								<div className="pt-3 footer-link">
									For Personal Enquiries:
								</div>
								<div>
									<a
										href="mailto: support@earnipay.com"
										className="hover:underline text-ep-primary-500"
									>
										{" "}
										support@earnipay.com
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="hidden pt-10 mt-20 border-t flex justify-between">
					<div>All Rights Reserved</div>
					<div>
						&copy; <span>{new Date().getFullYear()}</span> Earnipay
						Inc.
					</div>
				</div>
			</div>
			<div
				className="business-page-section"
				style={{ marginBottom: 0 }}
			>
				<div className="footer-tail-section">
					<img
						src={
							isMobile ? earnipayFooterBgMobile : earnipayFooterBg
						}
						alt="earnipay"
					/>
				</div>
			</div>
		</section>
	);
};

export default NewFooter;
