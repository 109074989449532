import React, { useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import quoteImage from "assets/images/icons/quoteImage.svg";
import prevArrow from "assets/images/prev-arrow.svg";
import nextArrow from "assets/images/next-arrow.svg";
import prevArrowMobile from "assets/images/prev-arrow-mobile.svg";
import nextArrowMobile from "assets/images/next-arrow-mobile.svg";
import { useMediaQuery } from "hooks/useMediaQuery";

const testimonies = [
	{
		content:
			"It has always been a race from pillar to post anytime there is a contract or job before we crossed paths with Earnipay. It has been a rain of opportunities ever since. The flexibility of Earnipay’s loans allowed us to keep our projects on schedule. Their understanding of our specific needs made all the difference.",
		name: "Engr. Adekunle Atta",
		company: "Managing Director @Kangau Technology Global Service",
	},
	{
		content:
			"I recently had the pleasure of experiencing the services of your organization and I must say, they exceeded my expectations in every way. From the moment I reached out, their team demonstrated a remarkable professionalism",
		name: "Taiwo Ifeimu",
		company: "Managing Director @Taitrice Nigeria Ltd",
	},
	{
		content:
			"Having started a business and financial relationship with Earnipay, we have grown in confidence to that of a company that has started taking projects of any costs. Earnipay is a firm with reliable source of confidence to us.",
		name: "Engr. Amiola Oyebiyi",
		company: "Managing Director @Omanco Construction Ltd",
	},
	{
		content:
			"With the little time we have been doing business with you, honestly we rate you 100% OK, your service are very very OK, we are here to say your service are the best among others, more blessings to the company more blessings to the staff.",
		name: "Engr. Adeleke Akeem Adeyemi",
		company: "Managing Director @Akleks Technical Ventures",
	},
	{
		content:
			"An excellent innovation. It is easy to access while the web and apps are user friendly. Earnipay has done an outstanding job in allowing staff access their pay.",
		name: "Obiora Mgbodile",
		company: "Brooks and Blake Nigeria Limited",
	},
];

const NextArrow = (props) => {
	const isMobile = useMediaQuery("(max-width: 768px");
	const { onClick } = props;

	return (
		<div
			className="what-customers-say-next-arrow what-customers-say-arrow"
			onClick={onClick}
		>
			<img
				src={isMobile ? nextArrowMobile : nextArrow}
				alt="next testimonial"
			/>
		</div>
	);
};

const PrevArrow = (props) => {
	const isMobile = useMediaQuery("(max-width: 768px");
	const { onClick } = props;

	return (
		<div
			className="what-customers-say-prev-arrow what-customers-say-arrow"
			onClick={onClick}
		>
			<img
				src={isMobile ? prevArrowMobile : prevArrow}
				alt="previous testimonial"
			/>
		</div>
	);
};

export const WhatCustomersSay = () => {
	const [activeIndex, setActiveIndex] = useState(0);
	const slickRef = useRef(null);

	const showBox = (testimony) => {
		if (testimony.content) {
			return "border shadow-sm";
		} else {
			return "";
		}
	};

	const slideshowProperties = {
		dots: true,
		appendDots: (dots) => (
			<ul style={{ margin: "0px" }}>
				{dots.map((dot, index) => (
					<li
						key={index}
						onClick={() => {
							slickRef?.current?.slickGoTo(index);
							setActiveIndex(index);
						}}
						className={`testimonial-slider-dot ${
							index === activeIndex
								? "testimonial-slider-active-dot"
								: ""
						}`}
					/>
				))}
			</ul>
		),
		beforeChange: (oldIndex, newIndex) => setActiveIndex(newIndex),
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
	};

	return (
		<div className="what-customers-say-section">
			<div className="relative what-customers-say-header-wrapper">
				<div className="">
					<div className="what-customers-say-header">
						<h3 className="what-customers-say-title">
							What Our Customers Are Saying About Us
						</h3>
					</div>
				</div>
				<div className="what-customers-say-wrapper">
					<div className="py-10 testimonials-landing">
						<Slider
							{...slideshowProperties}
							ref={slickRef}
							className="!max-w-[600px] h-full mx-auto"
							dots={true}
						>
							{testimonies.map((testimony, index) => (
								<div
									key={index}
									className="px-2 h-full"
								>
									<div
										className={`w-full h-full mx-auto text-base flex flex-col flex-shrink-0 p-10 rounded-3xl transition-transform duration-300 ${
											index === activeIndex
												? "transform scale-105 ease-in-out active-testimonial"
												: "inactive-testimonial"
										} ${showBox(testimony)}`}
									>
										<div className="testimony-content">
											{testimony.content}
										</div>
										<div className="flex justify-end">
											<img
												src={quoteImage}
												className="h-10"
												alt=""
											/>
										</div>

										<div className="overflow-hidden">
											<div
												title={testimony.name}
												className="testimony-name"
											>
												{testimony.name}
											</div>
											<div
												title={testimony.company}
												className="testimony-company"
											>
												{testimony.company}
											</div>
										</div>
									</div>
								</div>
							))}
						</Slider>
					</div>
				</div>
			</div>
		</div>
	);
};
