import React, { useState, forwardRef, useImperativeHandle } from "react";
import "assets/css/navbar.css";

export const NavBarDropdown = forwardRef((props, ref) => {
	const {
		dropdownTrigger,
		dropdownContent,
		navbarDropdownWrapperClassName,
		navbarDropdownContainerClassName,
	} = props;
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	const handleMouseEnter = () => {
		setIsDropdownOpen(true);
	};

	const handleMouseLeave = () => {
		setIsDropdownOpen(false);
	};

	useImperativeHandle(ref, () => ({
		handleToggleDropdown,
	}));

	const handleToggleDropdown = () => {
		setIsDropdownOpen(!isDropdownOpen);
	};

	return (
		<div
			className={`navbar-dropdown-wrapper ${navbarDropdownWrapperClassName}`}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<>{dropdownTrigger}</>
			{isDropdownOpen && (
				<ul
					className={`navbar-dropdown ${navbarDropdownContainerClassName}`}
				>
					{dropdownContent}
				</ul>
			)}
		</div>
	);
});
