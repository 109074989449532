import React from "react";
import Header from "components/common/header.jsx";
// import Footer from "components/common/footer.jsx";
import NewFooter from "components/common/new-footer";

const GeneralLayout = ({ children }) => {
	return (
		<React.Fragment>
			<Header />
			<div>
				<div className="general-layout-wrapper page-body overflow-auto">{children}</div>
			</div>
			<NewFooter />
		</React.Fragment>
	);
};

export default GeneralLayout;
